import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty, clone } from 'lodash';
import {
  Chip, Button, Icon, Paper, Typography,
  Grid, TextField, MenuItem, Select, OutlinedInput,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import { commonResourceStyle, tiffany } from '../../css/style';
import LoadingBar from '../subcomponents/LoadingBar';
import {
  socialMediaValues,
  BASE_URLS,
  HQ_ADMIN_KEY,
  EXTERNSHIP,
} from '../../data/Constants';
import { LocationSearchInput } from '../subcomponents/LocationSearchInput';
import PhotoGallery from '../subcomponents/PhotoGallery';
import EditorInput from '../subcomponents/EditorInput';
import { isDesktop, prunePhoneNumber } from '../../utils/Functions';
import SearchableSelect from '../subcomponents/SearchableSelect';

const data = [
  {
    key: 'defaultRange',
    title: 'Default Relief Range to Share Staff (miles)',
  },
  {
    key: 'email',
    title: 'E-mail',
  },
  {
    key: 'webpage',
    title: 'Webpage',
  },
];
const socialMediaData = [
  {
    key: 'social_media',
    title: 'Social Media',
    belongsTo: 'practices',
    width: '25%',
    margin: '20px',
    values: socialMediaValues,
  },
  {
    key: 'url',
    title: 'url',
    belongsTo: 'practices',
    width: '75%',
    margin: 0,
  },
];

const styles = commonResourceStyle();

class PracticesForm extends Component {
  done = false;

  constructor(props) {
    super(props);
    this.state = {
      id: null,
      aggregator_id: null,
      prevName: '',
      name: '',
      description: '',
      logo: '',
      location: '',
      postalCode: '',
      city: '',
      state: '',
      phoneNumber: '',
      faxNumber: '',
      email: '',
      webpage: '',
      lat: null,
      lng: null,
      socialMedias: [
        {
          key: 1,
          socialMedia: socialMediaValues[0],
          url: '',
        },
      ],
      address: '',
      practiceImages: [],
      newPracticeImages: [],
      deletedPracticeImages: [],
      isPasted: false,
      badgeConfig: {
        visible_badges: [],
      },
      aggregatorId: null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      resources, match, edit,
    } = this.props;
    const { practiceId } = match.params;
    const { adminPractices, socialmedias, practiceimages } = resources;
    // !isEqual(prevProps.resources, resources) &&
    if (!this.done && practiceId && adminPractices) {
      const filteredPractices = adminPractices.filter(item => item.id === Number(practiceId));
      if (filteredPractices.length) {
        const st = Object.assign(prevState, filteredPractices[0]);
        st.address = `${st.city} ${st.state} ${st.postalCode}`;
        Object.keys(st).forEach((key) => {
          // Set default as empty string for values used by TextField
          if (key !== 'aggregator_id' && key !== 'lat' && key !== 'lng' && key !== 'deletedAt' && st[key] === null) {
            st[key] = '';
          }
        });
        if (!edit) {
          delete st.id;
        }

        if (edit && socialmedias && socialmedias.length) {
          const practiceSocialMedias = socialmedias.filter(media => media.practice_id === Number(practiceId));
          if (practiceSocialMedias.length) {
            st.socialMedias = practiceSocialMedias;
          }
        }

        if (edit && practiceimages && practiceimages.length) {
          st.practiceImages = practiceimages;
        }

        st.prevName = st.name;
        this.setState(st);
        this.done = true;
      }
    }
  }

  componentDidMount = async () => {
    const {
      actions, apiToken, edit, match, admin, resources,
    } = this.props;
    const { adminPractices } = resources;
    const accessList = admin && admin.accessList || [];

    if (accessList.length > 0 && accessList[0]) {
      const aggregatorId = accessList[0].type === 'aggregator' ? accessList[0].id : accessList[0].aggregator_id;
      this.setState({ aggregator_id: aggregatorId });
    }

    if (apiToken) {
      if (!adminPractices) {
        await actions.getAllResources(apiToken, BASE_URLS.getPractices);
      }

      actions.getAllResources(apiToken, 'socialmedias');

      if (edit) {
        actions.getAllResources(apiToken, 'practiceimages', 'practice', match.params.practiceId);
      }

      if (admin && admin.adminRole === HQ_ADMIN_KEY) {
        actions.getAllResources(apiToken, 'badges');
      }
    }
  };

  getBase64 = file => (
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    })
  );

  handleFileChange = (event, key, identifier = null) => {
    this.getBase64(event.target.files[0]).then((base64) => {
      if (key === 'logo') {
        this.setState({ logo: base64 });
        return;
      }

      // No identifier = Add to newPracticeImages state
      if (identifier === null) {
        const newPracticeImages = clone(this.state.newPracticeImages);
        newPracticeImages.push({
          image: base64,
        });
        this.setState({ [key]: newPracticeImages });
        return;
      }

      let updatedPracticeImages;

      // Update existing practice image
      if (key === 'practiceImages') {
        const { practiceImages } = this.state;
        updatedPracticeImages = practiceImages.map((image) => {
          if (image.id === identifier) {
            image.image = base64;
          }
          return image;
        });
      } else {
        // Update new practice image
        const count = this.state.practiceImages.length;
        // 2 separate arrays containing practice images are used together. Use count as the offset to get the correct index.
        const newPracticeImagesIndex = count === 0 ? identifier : identifier - count;
        const { newPracticeImages } = this.state;
        updatedPracticeImages = newPracticeImages.map((image, index) => {
          if (index === newPracticeImagesIndex) {
            image.image = base64;
          }
          return image;
        });
      }

      this.setState({
        [key]: updatedPracticeImages,
      });
    }).catch(error => console.log('FileReader error'));
  };

  handleOnChange = (event, type, key) => {
    if (type === 'address') {
      this.setState({
        state: event.state,
        city: event.city,
        location: event.address,
        address: event.address,
        postalCode: event.postalCode,
        lat: event.lat,
        lng: event.lng,
      });
      return;
    } else if (type === 'practiceImages') {
      const deleted = clone(this.state.deletedPracticeImages);
      const filteredImages = this.state[type].filter((image) => {
        if (image.id === key) {
          deleted.push(image);
          return false;
        }
        return true;
      });
      this.setState({
        [type]: filteredImages,
        deletedPracticeImages: deleted,
      });
      return;
    } else if (type === 'newPracticeImages') {
      const count = this.state.practiceImages.length;
      // 2 separate arrays containing practice images are used together. Use count as the offset to get the correct index.
      const newPracticeImagesIndex = count === 0 ? key : key - count;
      const filteredImages = this.state[type].filter((image, index) => index !== newPracticeImagesIndex);
      this.setState({
        [type]: filteredImages,
      });
      return;
    } else if (type === 'phoneNumber') {
      const userInput = event.target.value;
      this.setState({ [type]: prunePhoneNumber(userInput) });
      return;
    }

    const { value } = event.target;
    const { socialMedias } = this.state;

    if (!key) {
      this.setState({
        [type]: value || '',
      });
      return;
    }

    const updatedSocialMedias = socialMedias.map((media) => {
      if (media.id === key || media.key === key) {
        media[type] = value;
      }
      return media;
    });

    this.setState({
      socialMedias: updatedSocialMedias,
    });
  };

  isDisabled = (key) => {
    const {
      name, postalCode, socialMedias,
    } = this.state;

    if (key === 'save') {
      return isEmpty(name && postalCode);
    }

    return !!socialMedias.find(media => !media.url);
  };

  handleOnClickAdd = () => {
    const { socialMedias } = this.state;
    socialMedias.push({
      key: socialMedias.length + 1,
      socialMedia: socialMediaValues[0],
      url: '',
    });
    this.setState({
      socialMedias,
    });
  };

  save = () => {
    const {
      actions, apiToken, history, edit, match,
    } = this.props;
    const {
      socialMedias, id, practiceImages, newPracticeImages, deletedPracticeImages, fastpassAllStaff = false,
    } = this.state;
    const { practiceId } = match.params;

    const saveData = { ...this.state, fastpassAllStaff };

    if (edit) {
      actions.updateResource(apiToken, saveData, 'practices', id)
        .then(() => actions.getAllResources(apiToken, BASE_URLS.getPractices));
      socialMedias.forEach((socialMedia) => {
        if (socialMedia.id) {
          actions.updateResource(apiToken, socialMedia, 'socialmedias', socialMedia.id);
        } else if (socialMedia.url) {
          socialMedia.practice_id = practiceId;
          actions.addResource(apiToken, socialMedia, 'socialmedias');
        }
      });
      if (newPracticeImages.length) {
        newPracticeImages.map((practiceImage) => {
          practiceImage.practice_id = practiceId;
          return practiceImage;
        }).forEach(practiceImage => actions.addResource(apiToken, practiceImage, 'practiceimages'));
      }
    } else {
      actions.addResource(apiToken, saveData, 'practices').then((response) => {
        if (!response.error) {
          const validSocialMedias = socialMedias.filter(media => media.url);
          if (validSocialMedias.length > 0) {
            validSocialMedias.map((media) => {
              media.practice_id = response.response.id;
              return media;
            });
            actions.addResource(apiToken, validSocialMedias, 'socialmedias', 'bulk');
          }
          if (newPracticeImages.length) {
            newPracticeImages.map((practiceImage) => {
              practiceImage.practice_id = response.response.id;
              return practiceImage;
            }).forEach(practiceImage => actions.addResource(apiToken, practiceImage, 'practiceimages'));
          }
          actions.getAllResources(apiToken, BASE_URLS.getPractices);
        }
      });
    }

    if (practiceImages.length) {
      practiceImages.forEach(practiceImage => actions.updateResource(apiToken, practiceImage, 'practiceimages', practiceImage.id));
    }

    if (deletedPracticeImages.length) {
      deletedPracticeImages.forEach(practiceImage => actions.deleteResource(apiToken, 'practiceimages', practiceImage.id));
    }

    if (this.checkQueryParam('signUp')) {
      history.push('/newposition?signUp=true');
    } else {
      // Wait for a while to go back and get updated data
      setTimeout(() => {
        history.goBack();
      }, 500);
    }
  };

  renderUploadImage = () => {
    const { classes, theme } = this.props;
    const { logo } = this.state;
    const stylesWithTheme = commonResourceStyle(theme);

    return (
      <div className={classes.editPracticeColumn1}>
        {
          logo && (
            <div className={classes.closeButtonContainer}>
              <Icon
                fontSize="small"
                sx={stylesWithTheme.closeImageButton}
                onClick={e => this.handleOnChange(e, 'logo')}
              >
                close
              </Icon>
            </div>
          )
        }
        <div style={{ height: '100%' }}>
          <Button
            sx={stylesWithTheme.editPracticeLogoBtn}
            style={{ backgroundImage: `url(${logo})` }}
            fullWidth
            variant="outlined"
            onClick={() => this.upload.click()}
          >
            {
              !logo && (
                <div className={classes.practiceUploadLogoText}>
                  <Icon>file_upload</Icon>
                  UPLOAD A LOGO
                </div>
              )
            }
            <input
              id="fileInput"
              type="file"
              style={{ display: 'none' }}
              accept="image/*"
              ref={ref => this.upload = ref}
              onChange={e => this.handleFileChange(e, 'logo')}
            />
          </Button>
        </div>
      </div>
    );
  };

  renderSocialMediaInput = () => {
    const { theme } = this.props;
    const { socialMedias } = this.state;
    const stylesWithTheme = commonResourceStyle(theme);

    return (
      socialMedias.map(media => (
        <div
          key={media.key || media.id}
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          {
            socialMediaData.map(data => (
              <div
                key={data.key}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: data.width,
                  marginRight: data.margin,
                }}
              >
                <Typography sx={data.key === 'url' ? stylesWithTheme.hidden : stylesWithTheme.subTitle}>
                  {data.title}
                </Typography>
                {
                  data.key === 'url' ? (
                    <TextField
                      value={media.url}
                      onChange={e => this.handleOnChange(e, 'url', media.id || media.key)}
                      variant="outlined"
                      fullWidth
                    />
                  ) : (
                      <Select
                        key={media.id || media.key}
                        value={media.socialMedia}
                        onChange={e => this.handleOnChange(e, 'socialMedia', media.id || media.key)}
                        input={(<OutlinedInput labelWidth={0} />)}
                        MenuProps={{
                          sx: {
                            '&& .Mui-selected': {
                              backgroundColor: `${tiffany} !important`,
                              opacity: 0.75,
                              color: '#fff',

                              '&:hover': {
                                backgroundColor: `${tiffany} !important`,
                                opacity: 0.75,
                                color: '#fff',
                              },
                            },
                          },
                        }}
                      >
                        {
                          data.values.map(value => (
                            <MenuItem key={value} value={value}>
                              {value}
                            </MenuItem>
                          ))
                        }
                      </Select>
                  )
                }
              </div>
            ))
          }
        </div>
      ))
    );
  };


  checkQueryParam(field) {
    let url = window.location.href;
    if (url.indexOf(`?${ field }=`) != -1) return true;
    else if (url.indexOf(`&${ field }=`) != -1) return true;
    return false;
  }


  getDashlet = (title, caption, style) => (
    <div style={style}>
      <div style={styles.dashlet}>
        <div style={styles.dashletTitle}>{title}</div>
        <div style={styles.dashletCaption}>{caption}</div>
      </div>
    </div>

  );

  renderExternshipBadge() {
    const {
      resources,
      admin,
      classes,
      theme,
    } = this.props;
    const { badges } = resources;
    const { badgeConfig, aggregator_id } = this.state;
    const { visible_badges } = badgeConfig;
    const badgeList = badges && badges.filter(x => (
      x.aggregator_id === aggregator_id
      && x.config && x.config.forceForJobTypes && x.config.forceForJobTypes.includes(EXTERNSHIP)
      && x.title !== 'Student'
    ));
    const stylesWithTheme = commonResourceStyle(theme);

    if (admin && admin.isExternshipAdmin) {
      if (admin.adminRole === HQ_ADMIN_KEY) {
        return (
          <div className={classes.externshipBadgeDropdown}>
            <SearchableSelect
              value={visible_badges || []}
              items={badgeList || []}
              labelCount={isDesktop(this.props.width) ? 3 : 2}
              ignoreFormControlStyle
              equalID
              label="Externship Badge"
              title="Please Select"
              onChange={(value) => {
                const badgeConfig = {
                  visible_badges: value,
                };
                this.setState({ badgeConfig });
              }}
            />
          </div>
        );
      }

      if (visible_badges && visible_badges.length > 0) {
        return (
          <div className={classes.chipGroup}>
            <Typography sx={stylesWithTheme.externshipBadgeLabel}>Externship Badge</Typography>
            {visible_badges && visible_badges.map(item => (
              (
                <Chip
                  key={item.key || item.id}
                  label={item.title || item.name}
                  color="secondary"
                  size="medium"
                  sx={{
                    ...stylesWithTheme.chipRootFull,
                    '& .MuiChip-label': stylesWithTheme.chipRootLabel,
                  }}
                />
              )
            ))}
            {visible_badges && visible_badges.length === 0 && (
              <Typography sx={stylesWithTheme.noContent}>
                Don't assign to any Externship Badge
              </Typography>
            )}
          </div>
        );
      }
      return null;
    }

    return null;
  }

  render() {
    const {
      classes, loading, viewMoreLoading, edit, theme,
    } = this.props;
    const {
      prevName,
      name,
      id,
      faxNumber,
      location,
      phoneNumber,
      address,
      description,
      practiceImages,
      newPracticeImages,
      postalCode,
      externalId,
    } = this.state;
    const isNew = !prevName;
    const isDuplicate = prevName && !edit;
    const isEdit = id && edit;
    const stylesWithTheme = commonResourceStyle(theme);

    return (
      (loading && viewMoreLoading === 0)
        ? <LoadingBar />
        : (
          <div className={classes.container}>
            <div className={classes.resourceHeader}>

              {
                this.checkQueryParam('signUp')
                && (
                <div style={{ marginBottom: '15px', display: 'flex' }}>
                  {this.getDashlet('STEP 1', 'Create a Practice')}
                  {this.getDashlet('STEP 2', 'Create a Position', { opacity: '0.5' })}
                </div>
                )
              }

              <div className={classes.resourceNameLarge}>
                <Typography sx={stylesWithTheme.resourceNameLarge}>
                  {isNew && 'NEW PRACTICE'}
                  {isDuplicate && `Copy Of ${prevName}`}
                  {isEdit && 'Edit PRACTICE'}
                </Typography>
                <Button
                  sx={this.isDisabled('save') ? stylesWithTheme.headerDisabledButton : stylesWithTheme.headerButton}
                  onClick={this.save}
                  disabled={this.isDisabled('save')}
                >
                  SAVE
                </Button>
              </div>
            </div>
            <Paper sx={stylesWithTheme.paper} style={{ overflow: 'auto' }}>
              <div className={classes.paperContainer}>
                {externalId && (<Typography sx={stylesWithTheme.resourceAlert}>{'>>>To edit unavailable fields, please update information in Workday.<<<'}</Typography>)}
                <Typography sx={stylesWithTheme.resourceSub}>About The Practice</Typography>
                <Grid container spacing={2} style={{ marginBottom: '20px' }}>
                  <Grid item md={6}>
                    <div className={classes.editPracticeContainer}>
                      <div className={classes.editPracticeRow}>
                        {
                          this.renderUploadImage()
                        }
                        <div className={classes.editPracticeColumn2}>
                          <div className={classes.formControlLabelContainer}>
                            <Typography sx={stylesWithTheme.subTitle}>
                              Practice Name
                            </Typography>
                            {!name && <Typography sx={stylesWithTheme.warningText}>This field is required</Typography>}
                          </div>
                          <TextField
                            value={name}
                            multiline
                            rows={2}
                            maxRows={2}
                            onChange={e => this.handleOnChange(e, 'name')}
                            fullWidth
                            variant="outlined"
                            InputProps={{ classes: { notchedOutline: name ? null : classes.invalidInput } }}
                          />
                          <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                          >
                            <div className={classes.phoneNumberContainer}>
                              <Typography sx={stylesWithTheme.subTitle}>
                                Phone Number
                              </Typography>
                              <TextField
                                value={phoneNumber}
                                onChange={e => this.handleOnChange(e, 'phoneNumber')}
                                onPaste={() => this.setState({ isPasted: true })}
                                variant="outlined"
                                disabled={!!externalId}
                                fullWidth
                              />
                            </div>
                            <div className={classes.faxNumberContainer}>
                              <Typography sx={stylesWithTheme.subTitle}>
                                Fax Number
                              </Typography>
                              <TextField
                                value={faxNumber}
                                onChange={e => this.handleOnChange(e, 'faxNumber')}
                                variant="outlined"
                                disabled={!!externalId}
                                fullWidth
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                      >
                        <div style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                        }}
                        >
                          <div className={classes.cityContainer}>
                            <div className={classes.formControlLabelContainer}>
                              <Typography sx={stylesWithTheme.subTitle}>
                                Practice Zip Code
                              </Typography>
                              {!postalCode && <Typography sx={stylesWithTheme.warningText}>This field is required</Typography>}
                            </div>
                            <LocationSearchInput
                              variant="outlined"
                              address={address}
                              onChange={value => this.handleOnChange(value, 'address')}
                              fullWidth
                              placeholder=""
                              InputProps={{
                                classes: { notchedOutline: postalCode ? null : classes.invalidInput },
                                disabled: !!externalId,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      {
                        this.renderExternshipBadge()
                      }
                      {
                        data?.map(data => (
                          <div key={data.key} style={{ flexDirection: 'column' }}>
                            <Typography sx={stylesWithTheme.subTitle}>
                              {data.title}
                            </Typography>
                            <TextField
                              value={this.state[data.key]}
                              onChange={e => this.handleOnChange(e, data.key)}
                              variant="outlined"
                              fullWidth
                            />
                          </div>
                        ))
                      }
                      {
                        this.renderSocialMediaInput()
                      }
                      <Button
                        sx={stylesWithTheme.addNewSocialMedia}
                        disabled={this.isDisabled('newSM')}
                        onClick={this.handleOnClickAdd}
                      >
                        <Icon>add_circle_outline</Icon>
                        ADD NEW SOCIAL MEDIA
                      </Button>

                      <div style={{ flexDirection: 'column' }}>
                        <Typography sx={stylesWithTheme.subTitle}>
                          Photo Gallery
                        </Typography>
                        <PhotoGallery
                          practiceImages={practiceImages.concat(newPracticeImages)}
                          handleFileChange={(e, key, identifier) => this.handleFileChange(e, key, identifier)}
                          handleOnChange={(e, type, key) => this.handleOnChange(e, type, key)}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item md={6}>
                    <EditorInput
                      value={description}
                      title="Description (to be shown at Position post section)"
                      onChange={description => this.setState({ description })}
                      customFormControlStyle={styles.customFormControlStyle}
                      customLabelStyle={{ marginBottom: '5px', fontSize: '0.875rem' }}
                    />
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </div>
        )
    );
  }
}

PracticesForm.propTypes = {};

PracticesForm.defaultProps = {};

export default withStyles(commonResourceStyle, { withTheme: true })(withRouter(PracticesForm));
