const types = {
  CLEAR_MESSAGE: 'CLEAR_MESSAGE',
  ERROR: 'ERROR',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGOUT_USER: 'LOGOUT_USER',
  RENDER_MESSAGE: 'RENDER_MESSAGE',

  LOGIN_REQUESTED: 'LOGIN_REQUESTED',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',

  SEND_SIGN_UP_REQUEST: 'SEND_SIGN_UP_REQUEST',
  SEND_SIGN_UP_REQUEST_SUCCESS: 'SEND_SIGN_UP_REQUEST_SUCCESS',
  SEND_SIGN_UP_REQUEST_FAILURE: 'SEND_SIGN_UP_REQUEST_FAILURE',

  REGISTER_USER_REQUEST: 'REGISTER_USER_REQUEST',
  REGISTER_USER_SUCCESS: 'REGISTER_USER_SUCCESS',
  REGISTER_USER_FAILURE: 'REGISTER_USER_FAILURE',
  UPDATE_ADMIN_FAILURE: 'UPDATE_ADMIN_FAILURE',
  UPDATE_ADMIN_REQUESTED: 'UPDATE_ADMIN_REQUESTED',
  UPDATE_ADMIN_SUCCESS: 'UPDATE_ADMIN_SUCCESS',

  SIGNOUT_USER: 'SIGNOUT_USER',
  FILTER_PRODUCTS: 'FILTER_PRODUCTS',
  SET_ADMIN: 'SET_ADMIN',
  SET_STRIPE_ENABLED: 'SET_STRIPE_ENABLED',

  DELETE_RESOURCE_SUCCESS: 'DELETE_RESOURCE_SUCCESS',
  ADD_RESOURCE_SUCCESS: 'ADD_RESOURCE_SUCCESS',
  UPDATE_RESOURCE_SUCCESS: 'UPDATE_RESOURCE_SUCCESS',
  GET_ALL_RESOURCES_SUCCESS: 'GET_ALL_RESOURCES_SUCCESS',
  GET_ONE_RESOURCE_SUCCESS: 'GET_ONE_RESOURCE_SUCCESS',
  GET_DOWNLOAD_RESOURCES_SUCCESS: 'GET_DOWNLOAD_RESOURCES_SUCCESS',
  UPLOAD_RESOURCE_SUCCESS: 'UPLOAD_RESOURCE_SUCCESS',
  GET_RECENT_ORDERS_SUCCESS: 'GET_RECENT_ORDERS_SUCCESS',
  VERIFY_USER_SUCCESS: 'VERIFY_USER_SUCCESS',
  CREATE_STRIPE_SUCCESS: 'CREATE_STRIPE_SUCCESS',
  CANCEL_STRIPE_SUBSCRIPTION_SUCCESS: 'CANCEL_STRIPE_SUBSCRIPTION_SUCCESS',

  DELETE_RESOURCE_REQUESTED: 'DELETE_RESOURCE_REQUESTED',
  ADD_RESOURCE_REQUESTED: 'ADD_RESOURCE_REQUESTED',
  UPDATE_RESOURCE_REQUESTED: 'UPDATE_RESOURCE_REQUESTED',
  GET_ALL_RESOURCES_REQUESTED: 'GET_ALL_RESOURCES_REQUESTED',
  GET_ONE_RESOURCE_REQUESTED: 'GET_ONE_RESOURCE_REQUESTED',
  GET_DOWNLOAD_RESOURCE_REQUESTED: 'GET_DOWNLOAD_RESOURCE_REQUESTED',
  UPLOAD_RESOURCE_REQUESTED: 'UPLOAD_RESOURCE_REQUESTED',
  GET_RECENT_ORDERS_REQUESTED: 'GET_RECENT_ORDERS_REQUESTED',
  VERIFY_USER_REQUEST: 'VERIFY_USER_REQUEST',
  CREATE_STRIPE_REQUESTED: 'CREATE_STRIPE_REQUESTED',
  CANCEL_STRIPE_SUBSCRIPTION_REQUESTED: 'CANCEL_STRIPE_SUBSCRIPTION_REQUESTED',

  VIEW_MORE_RESOURCE_REQUESTED: 'VIEW_MORE_RESOURCE_REQUESTED',
  VIEW_MORE_RESOURCE_SUCCESS: 'VIEW_MORE_RESOURCE_SUCCESS',

  VERIFY_RECOVERY_TOKEN_SUCCESS: 'VERIFY_RECOVERY_TOKEN_SUCCESS',
  GET_RECOVERY_TOKEN_SUCCESS: 'GET_RECOVERY_TOKEN_SUCCESS',
  CONFIRM_RESET_PASSWORD_SUCCESS: 'CONFIRM_RESET_PASSWORD_SUCCESS',

  SEND_PUSH_NOTIFICATION: 'SEND_PUSH_NOTIFICATION',
  SEND_PUSH_NOTIFICATION_SUCCESS: 'SEND_PUSH_NOTIFICATION_SUCCESS',
  ADD_RESOURCES_FILTER: 'ADD_RESOURCES_FILTER',
};

export default types;
