import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import DisplayResources from '../crud/DisplayResources';
import {
  resourceFields,
  connectionsPaginationCount,
  jobPostingsPaginationCount,
  VET_TYPES,
  BASE_URLS,
  HQ_ADMIN_KEY,
  IMG_STORAGE_LINK,
} from '../../data/Constants';
import {
  Button, Paper, Typography, Icon, Box, Hidden,
} from '@mui/material';
import { commonResourceStyle } from '../../css/style';
import LoadingBar from '../subcomponents/LoadingBar';
import types from '../../actions/ActionTypes';
import DateFormat from '../subcomponents/DateFormat';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchableSelect from '../subcomponents/SearchableSelect';
import queryString from 'query-string';

import {
  utils, mapConnections, getBackgroundColor, scrollToItem, formatPostTitle,
} from '../../utils/Functions';
import { withStyles } from '@mui/styles';
import Dashboard from '../subcomponents/Dashboard';
import ConnectionCard from '../subcomponents/ConnectionCard';
import { NavigateNext } from '@mui/icons-material';

const styles = commonResourceStyle();

class Connections extends Component {
  constructor(props) {
    super(props);
    const connectionFilters = (props.filters && props.filters.connections) ? props.filters.connections : {};
    this.state = {
      selectedPractices: connectionFilters.selectedPractices || [],
      selectedJobPostings: connectionFilters.selectedJobPostings || [],
      selectedTalentTypes: connectionFilters.selectedTalentTypes || [],
      totalUnreadMessagesCount: 0,
      startPosition: 0,
    };
  }

  componentDidMount = () => {
    const {
      actions, loading, apiToken, connections,
    } = this.props;

    let filtersObj = {};
    const jobPostingQueryParams = `jobpostings?practices=&start=0&count=${jobPostingsPaginationCount}`;
    let queryParams = `connections?start=0&count=${connectionsPaginationCount}${this.getFilterParams()}`;
    if (location.search) {
      filtersObj = utils.queryParamsToObject(location.search);
      queryParams = `connections${location.search}`;
    } else {
      this.clearFilters();
    }
    actions.getAllResources(apiToken, queryParams).then((res) => {
      if (!res.error) {
        const connections = res.response.list;
        const totalUnreadMessagesCount = connections.map(connect => connect.unreadMessagesCount).reduce((a, b) => a + b, 0);
        this.setState({ totalUnreadMessagesCount });
      }
    });
    actions.getAllResources(apiToken, 'connections/statistics');

    if (filtersObj.practices) {
      actions.getAllResources(apiToken, BASE_URLS.getPractices).then((resp) => {
        utils.setPrevFilter(filtersObj.practices, resp.response, 'id', (selectedItems) => {
          this.setState({ ['selectedPractices']: selectedItems });
        });
      });
    } else {
      actions.getAllResources(apiToken, BASE_URLS.getPractices);
    }

    if (filtersObj.jobpostings) {
      actions.getAllResources(apiToken, jobPostingQueryParams).then((resp) => {
        utils.setPrevFilter(filtersObj.jobpostings, resp.response.list, 'id', (selectedItems) => {
          this.setState({ ['selectedJobPostings']: selectedItems });
        });
      });
    } else {
      actions.getAllResources(apiToken, jobPostingQueryParams);
    }


    if (filtersObj.talentTypes) {
      utils.setPrevFilter(filtersObj.talentTypes, VET_TYPES, 'key', (selectedItems) => {
        this.setState({ ['selectedTalentTypes']: selectedItems });
      });
    }
  };


  componentDidUpdate = (prevProps) => {
    scrollToItem(prevProps, this.props, 'homeVerticalScroll');
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { jobpostings, location } = nextProps;
    const { selectedJobPostings } = prevState;
    if (!selectedJobPostings.length) {
      const pageParams = queryString.parse(location.search);
      let { jobposting } = pageParams;
      const items = (jobposting && jobpostings) ? jobpostings.filter(item => item.id === Number(jobposting)) : [];
      return { selectedJobPostings: items };
    }
    return null;
  }

  getFilterParams = (e) => {
    const {
      selectedPractices,
      selectedJobPostings,
      selectedTalentTypes,
    } = this.state;
    const { location } = this.props;
    const pageParams = queryString.parse(location.search);
    let { jobposting } = pageParams;

    return `&practices=${
      selectedPractices.map(item => item.id).join(',')
    }&jobpostings=${
      selectedJobPostings.map(item => item.id).join(',') || jobposting || ''
    }&talentTypes=${
      selectedTalentTypes.map(item => item.key).join(',')
    }`;
  };


  setQueryParams(queryParams) {
    const query = queryParams.replace('connections', '');
    history.pushState(null, '', query);
  }

  applyFilters = () => {
    const {
      actions,
      apiToken,
      loading,
    } = this.props;
    const {
      selectedPractices,
      selectedJobPostings,
      selectedTalentTypes,
    } = this.state;
    if (!loading) {
      const queryParams = `connections?start=0&count=${connectionsPaginationCount}${this.getFilterParams()}`;
      this.setQueryParams(queryParams);
      actions.updateLoadingState(types.VIEW_MORE_RESOURCE_REQUESTED);
      actions.getAllResources(apiToken, queryParams);
      actions.updateLoadingState(types.VIEW_MORE_RESOURCE_SUCCESS);
    }
    actions.addResourceFilter('connections', { selectedPractices, selectedJobPostings, selectedTalentTypes });
  };

  clearFilters = () => {
    const { selectedPractices, selectedJobPostings, selectedTalentTypes } = this.state;
    if (selectedPractices.length || selectedJobPostings.length || selectedTalentTypes.length) {
      this.setState({
        selectedPractices: [],
        selectedJobPostings: [],
        selectedTalentTypes: [],
      }, () => this.applyFilters());
    }
  };

  onClickViewMore = async () => {
    const {
      actions,
      apiToken,
      pagination,
      loading,
    } = this.props;
    if (!loading) {
      const queryParams = `connections?start=${pagination.start + pagination.count}&count=${connectionsPaginationCount}${this.getFilterParams()}`;
      actions.updateLoadingState(types.VIEW_MORE_RESOURCE_REQUESTED);
      actions.getAllResources(apiToken, queryParams);
      actions.updateLoadingState(types.VIEW_MORE_RESOURCE_SUCCESS);
    }
  };

  getDashboard = () => {
    const { statistics } = this.props;
    const { totalUnreadMessagesCount, startPosition } = this.state;

    if (statistics) {
      const {
        newConnections, firstNewConnectionDate, totalConnections, firstConnectionDate, totalMatches, firstMatchDate, positionClicks, firstClickDate, activePositions, archivedPositions,
      } = this.props.statistics;
      const dashlets = [
        {
          pos: 0,
          value: newConnections,
          icon: 'new_releases',
          title: 'New Applications',
          caption: (<DateFormat str={firstNewConnectionDate} prepend="since" />),
          toolTip: 'new connection requests below from people interested in a particular post',
          path: '/applications',
        },
        {
          pos: 1,
          value: totalUnreadMessagesCount,
          icon: 'chat',
          title: 'New Messages',
          caption: null,
          toolTip: 'total count of unread messages',
          path: '/applications',
        },
        {
          pos: 2,
          value: totalConnections,
          icon: 'insert_emoticon',
          title: 'Applications',
          caption: (<DateFormat str={firstConnectionDate} format="MMMM, yyyy" prepend="since" />),
          toolTip: 'accumulated connections made for all posts',
          path: '/applications',
        },
        {
          pos: 3,
          value: activePositions,
          icon: 'business_center',
          title: 'Active Positions',
          caption: `${archivedPositions} archived position`,
          toolTip: 'total number of active posts in all hospitals right now',
          path: '/jobpostings',
        },
        {
          pos: 4,
          value: totalMatches,
          icon: 'track_changes',
          title: 'Total Matches',
          caption: (<DateFormat str={firstMatchDate} format="MMMM, yyyy" prepend="since" />),
          toolTip: 'accumulated number of times that job match notifications have been sent to talent users',
          path: '/jobpostings',
        },
        {
          pos: 5,
          value: positionClicks,
          icon: 'touch_app',
          title: 'Position Clicks',
          caption: (<DateFormat str={firstClickDate} format="MMMM, yyyy" prepend="since" />),
          toolTip: 'accumulated times users have clicked through to read the full job description',
          path: '/jobpostings',
        },
      ];
      return (
        <Dashboard
          dashlets={dashlets}
          display={3}
          startPosition={startPosition}
          handleOnClick={position => this.setState({ startPosition: position })}
        />
      );
    }
  };

  getTalentType = data => (<div style={styles.capitalize}>{utils.toTitleCase(data.jobPosting.talentType)}</div>);

  getConnectedAt = data => <DateFormat str={data.connectDate} moment />;

  getFullNameColumn = data => `${data.talentPII.firstName} ${data.talentPII.lastName}`;

  getTalentTypeIcon = (data, isCard) => {
    let image = null;

    if (data && data.jobPosting && data.jobPosting.talentType) {
      image = `${IMG_STORAGE_LINK}talent-types/${data.jobPosting.talentType}.png`;
    }

    const isNew = !data.adminViewDate;
    let style;

    if (isCard && isNew) {
      style = { ...styles.talentTypeIconCard, marginLeft: '10px' };
    } else if (isCard) {
      style = styles.talentTypeIconCard;
    } else {
      style = styles.fullNameColumnConnections;
    }

    return (
      <div style={style}>
        {isNew && (
          <div style={isCard ? styles.cardNewItem : styles.newItem}>
            <Icon style={styles.newItemIcon}>new_releases</Icon>
            new
          </div>
        )}
        {image && <img style={isCard ? styles.talentTypeIconImage : styles.fullNameColumnImageConnection} src={image} alt="Selectable Icon" />}
      </div>
    );
  };

  getFilter = (items, type, title) => {
    const value = this.state[type];
    return (
      <SearchableSelect value={value} title={title} items={items} onChange={value => this.setState({ [type]: value })} filter />
    );
  };

  getFilters = () => {
    const { adminPractices, jobpostings, classes } = this.props;
    const { selectedJobPostings, selectedPractices, selectedTalentTypes } = this.state;
    return (
      <div style={styles.filterContainer}>
        <Paper style={styles.paperFilters}>
          {adminPractices && this.getFilter(adminPractices, 'selectedPractices', 'All Practices')}
          {this.getFilter(VET_TYPES, 'selectedTalentTypes', 'All Employee Types')}
          {jobpostings && this.getFilter(jobpostings, 'selectedJobPostings', 'All Open Posts')}
          <Button variant="contained" color="primary" onClick={e => this.applyFilters()}>Filter</Button>
        </Paper>
        {
          (selectedJobPostings.length || selectedPractices.length || selectedTalentTypes.length)
            ? (
              <Button
                className={classes.clearFilterText}
                onClick={() => this.clearFilters()}
                disableRipple
                variant='text'
              >
                clear filters
              </Button>
            )
            : <div />
        }
      </div>
    );
  };

  mapJobpostings = jobpostings => jobpostings.filter(posting => posting.id !== 'viewMore').map(posting => ({
    key: posting.id,
    title1: 'NAME',
    value1: formatPostTitle(posting.title),
    title2: 'TYPE',
    value2: posting.typeOfEmployment,
    title3: 'PRACTICE NAME',
    value3: posting && posting.practice && posting.practice.name,
    title4: 'ROTATION TYPES',
    value4: posting && posting.rotationTypes && JSON.parse(posting.rotationTypes),
  }));

  renderCardContent = (mutableConnections = [], mutableJobpostings = []) => (
    <Box sx={{ ...styles.altConnectionsContainer, display: { xs: 'flex', md: 'none' } }}>
      <div style={styles.altAppsContainer}>
        <Typography style={styles.altConnectionsTitle} onClick={() => this.props.history.push('/applications')}>
          RECENT APPLICATIONS
          <span style={styles.chevronsIndicator}>{'>>'}</span>
        </Typography>
        {mutableConnections.length
          ? mapConnections(mutableConnections, this.getTalentTypeIcon).map((connection, i) => (
            <ConnectionCard
              key={connection.key}
              data={connection}
              backgroundColor={getBackgroundColor(i)}
              history={this.props.history}
              onClick={() => {
                sessionStorage.setItem('homeVerticalScroll', window.pageYOffset);
                this.props.history.push(`/connections/${connection.key}/view`);
              }}
            />
          ))
          : <Typography style={styles.text}>No recent applications</Typography>
        }
        <Button onClick={() => this.props.history.push('/applications')} style={styles.viewMoreApplicationsButton}>
          ALL APPLICATIONS
          <NavigateNext style={styles.navigateNextIcon} />
        </Button>
      </div>
      <div style={styles.altAppsContainer}>
        <Typography style={styles.altConnectionsTitle} onClick={() => this.props.history.push('/jobpostings')}>
          RECENT POSTINGS
          <span style={styles.chevronsIndicator}>{'>>'}</span>
        </Typography>
        {mutableJobpostings.length
          ? this.mapJobpostings(mutableJobpostings).map((jobposting, i) => (
            <ConnectionCard
              key={jobposting.key}
              data={jobposting}
              backgroundColor={getBackgroundColor(i)}
              onClick={() => this.props.history.push(`/editposition/${jobposting.key}`)}
            />
          ))
          : <Typography style={styles.text}>No recent posts</Typography>
        }
        <Button onClick={() => this.props.history.push('/jobpostings')} style={styles.viewMoreApplicationsButton}>
          ALL POSTS
          <NavigateNext style={styles.navigateNextIcon} />
        </Button>
      </div>
    </Box>
  );

  render() {
    const {
      classes, connections, actions, loading, apiToken, viewMoreLoading, pagination, admin, history, jobpostings,
    } = this.props;

    const isHQAdmin = admin && admin.adminRole === HQ_ADMIN_KEY;
    const isShowButton = isHQAdmin || (!isHQAdmin && admin.isShiftAdmin);
    const customRender = {
      talentTypeIcon: this.getTalentTypeIcon,
      fullName: this.getFullNameColumn,
      connectedAt: this.getConnectedAt,
      talentType: this.getTalentType,
    };

    const mutableConnections = cloneDeep(connections);
    if (mutableConnections && mutableConnections.length != 0) {
      mutableConnections.push({
        id: 'viewMore',
        value: 'ALL APPLICATIONS',
      });
    }
    let mutableJobPostings = [];
    if (jobpostings && jobpostings.length) {
      mutableJobPostings = cloneDeep(jobpostings).sort((a, b) => {
        if (a.createdAt > b.createdAt) {
          return -1;
        }
        if (a.createdAt < b.createdAt) {
          return 1;
        }
        return 0;
      })
        .filter(posting => !posting.deletedAt && !posting.isDeleted).slice(0, 5);
    }

    if (mutableJobPostings && mutableJobPostings.length != 0) {
      mutableJobPostings.push({
        id: 'viewMore',
        value: 'ALL POSTS',
      });
    }

    return (
      (loading === true && viewMoreLoading === 0)
        ? <LoadingBar />
        : (
          <div className={classes.container}>
            <div className={classes.homeResourceHeader}>
              <div className={classes.resourceName}>
                <Typography sx={styles.homeResourceName}>
                  <Icon style={{ marginRight: '5px' }}>home</Icon>
                  {`Welcome, ${admin.name}`}
                </Typography>
              </div>
              {this.getDashboard()}
            </div>
                  <Box sx={{ ...styles.connectionsContainer, display: { xs: 'none', md: 'flex' } }}>
                    <Box sx={styles.recentAppsContainer}>
                      <Typography
                        sx={styles.connectionsTitle}
                        onClick={() => history.push('/applications')}
                      >
                        RECENT APPLICATIONS
                        <span style={styles.chevronsIndicator}>{'>>'}</span>
                      </Typography>
                      <Paper style={styles.paperConnections}>
                        <DisplayResources
                          data={mutableConnections}
                          actions={actions}
                          fieldNames={resourceFields.connections}
                          customRender={customRender}
                          resourceName="connections"
                          apiToken={apiToken}
                          viewOnly
                          onClickViewMore={this.onClickViewMore}
                          totalResource={pagination && pagination.totalSize}
                          viewMoreLoading={viewMoreLoading}
                        />
                      </Paper>
                    </Box>
                    <Box sx={styles.recentPostingsContainer}>
                      <Typography
                        sx={styles.connectionsTitle}
                        onClick={() => history.push('/jobpostings')}
                      >
                        RECENT POSTINGS
                        <span style={styles.chevronsIndicator}>{'>>'}</span>
                      </Typography>
                      <Paper style={styles.paperConnections}>
                        <DisplayResources
                          data={mutableJobPostings}
                          actions={actions}
                          fieldNames={resourceFields.jobPostings}
                          customRender={customRender}
                          resourceName="jobpostings"
                          apiToken={apiToken}
                          viewOnly
                        />
                      </Paper>
                    </Box>
                  </Box>
                {this.renderCardContent(mutableConnections, mutableJobPostings)}
            {isShowButton && (
              <Hidden smDown>
                <div
                  color="secondary"
                  aria-label="add"
                  className={classes.fabButton}
                  onClick={e => history.push('/newposition')}
                >
                  <AddCircleOutlineIcon />
                  Add New Position
                </div>
              </Hidden>
            )}
            {isShowButton && (
              <Hidden mdUp>
                <div
                  style={{ width: '100%' }}
                  onClick={e => history.push('/newposition')}
                >
                  <div style={styles.altAppsContainer}>
                    <Button style={styles.addNewPositionButton}>
                      <AddCircleOutlineIcon style={styles.addNewPositionIcon} />
                      Add New Position
                    </Button>
                  </div>
                </div>
              </Hidden>
            )}
          </div>
        )
    );
  }
}

Connections.propTypes = {
  loading: PropTypes.bool.isRequired,
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  apiToken: PropTypes.string.isRequired,
};

Connections.defaultProps = {};

export default withStyles(commonResourceStyle)(withRouter(Connections));
