import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import {
  Typography,
  Card,
  CardContent,
  TextField,
  Button,
  OutlinedInput,
  MenuItem,
  Select,
  Popover,
  Paper, Box, Icon, IconButton,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { MuiChipsInput } from 'mui-chips-input';

import { emailFrequencyValues, IMG_STORAGE_LINK } from '../../data/Constants';
import types from '../../actions/ActionTypes';
import {
  hoverEffectOne,
  orange,
  tiffany,
  autocompleteOptionStyle,
} from '../../css/style';
import {
  validateEmail,
  validateMobile,
  prunePhoneNumber,
  handleTrailingSpaces,
  updateAdminAccessList,
  isTPHCUser,
  updateLocalAdmin,
} from '../../utils/Functions';
import MessageSnackbar from '../core/components/MessageSnackbar';
import DeleteConfirmDialog from '../subcomponents/DeleteConfirmDialog';
import GreenSwitch from '../subcomponents/GreenSwitch';
import UsersForm from './UsersForm';
import ReleaseNoteDialog from '../subcomponents/ReleaseNoteDialog';

const styles = {
  checked: {},
  focusVisible: {},
  expandMoreIcon: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: 'black',
    marginRight: '14px',
    marginTop: '3px',
  },
  settingImage: {
    position: 'absolute',
    right: '0px',
    top: '3px',
  },
  main: {
    marginBottom: '40px',
    fontSize: '30px',
    fontWeight: 'bold',
    color: 'white',
    textTransform: 'uppercase',
    fontFamily: 'circe-rounded',
    marginBottom: '10px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    button: {
      marginBottom: '10px',
    },
  },
  settingLogo: {
    width: '34px',
    marginRight: '5px',
    verticalAlign: 'sub',
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    margin: '2% 3%',
    overflow: 'hidden',
    justifyContent: 'flex-start',
  },
  card: {
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.3)',
    marginBottom: '40px',
  },
  cardContent: {
    padding: ' 25px 25px 15px 25px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  title: {
    fontWeight: 'bold',
    marginBottom: '15px',
    fontSize: '16px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  rowNotification: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  textFieldContainer: {
    width: '100%',
    margin: '10px 0',
  },
  textField: {
    width: '98%',
    margin: '10px 0',
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none',
    },
  },
  textFieldInput: {
    fontSize: '15px',
    '&::placeholder': {
      color: 'black !important',
      opacity: '100%',
    },
  },
  chipInput: {
    width: '100%',
    margin: '10px 0',
    '& .MuiInputBase-root': {
      padding: '5px 8px !important',
      '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: `${tiffany} !important`,
      },
    },
  },
  button: {
    width: '200px',
    height: '40px',
    backgroundColor: `${orange}`,
    color: '#ffffff',
    marginTop: '20px',
    alignSelf: 'flex-end',
    fontSize: '12px',
    fontWeight: 'bold',
    '&:disabled': {
      backgroundColor: '#f0f0f0',
    },
    ...hoverEffectOne,
  },
  '@keyframe splashEffect': {
    '0%': {
      background: `${orange}`,
    },
    '100%': {
      background: '#ffffff',
    },
  },
  animatedIcon: {
    animation: '$scale-effect 1.5s infinite',
    fontSize: '10px',
    margin: '0 10px 12px 5px',
    color: `${orange}`,
  },
  '@keyframes scale-effect': {
    '0%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(1.5)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
  subTitle: {
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  buttonRight: {
    width: '200px',
    height: '40px',
    backgroundColor: `${orange}`,
    color: '#ffffff',
    marginTop: '20px',
    alignSelf: 'flex-end',
    fontSize: '12px',
    fontWeight: 'bold',
    '&:disabled': {
      backgroundColor: '#f0f0f0',
    },
    ...hoverEffectOne,
    marginLeft: '10px',
  },
  label: {
    color: '#ababab',
    fontSize: '14px',
    display: 'flex',
  },
  info: {
    fontSize: '14px',
    display: 'flex',
  },
  select: {
    width: '25%',
    fontSize: '15px',
    height: '46px',
    margin: '10px 0 10px 0px',
  },
  longTextField: {
    width: '50%',
    fontSize: '15px',
    margin: 'auto 0px auto 24px',
  },
  fullWidthTextField: {
    width: '100%',
    margin: '10px 0',
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none',
    },
  },
  subTitleHelpIcon: {
    marginLeft: '5px',
    height: '18px',
    verticalAlign: 'text-bottom',
  },
  helpOutlineIcon: {
    marginLeft: '2px',
    height: '18px',
  },
  helpPopoverText: {
    whiteSpace: 'pre-line',
  },
  helpPopover: {
    maxWidth: '400px',
    padding: '10px',
  },
};

const cards = [
  {
    id: 1,
    title: 'CHANGE PASSWORD',
    rows: [
      [
        {
          input: 'password1',
          placeholder: 'New Password',
        },
        {
          input: 'password2',
          placeholder: 'Re-Type New Password',
        },
      ],
    ],
    buttonTitle: 'CHANGE PASSWORD',
  },
  {
    id: 2,
    title: 'NOTIFICATIONS',
    rows: [
      [
        {
          label: 'Email Frequency:',
        },
        {
          label: 'Recipients (Please type each email and press Enter):',
          help: 'Add a second email address to be copied on notification messages (in case you don\'t check your professional email account address as often as you\'d like).',
        },
      ],
      [
        {
          input: 'emailFrequency',
        },
        {
          input: 'recipients',
        },
      ],
      [
        {
          label: 'Real-Time Email',
          help: 'Check here to receive real-time email notifications about new connections!',
          radio: true,
        },
        {
          label: 'Real-Time Sms',
          help: 'Check here to receive real-time sms notifications about new connections!',
          isRealTimeSms: true,
          radio: true,
        },
        {
          label: 'Update staff reminder (monthly)',
          help: '',
          radio: true,
          isUpdateStaffReminder: true,
        },
      ],
      [
        {
          input: 'isRealTimeEmailNotification',
          radio: true,
        },
        {
          input: 'isRealTimeSmsNotification',
          radio: true,
          isRealTimeSms: true,
        },
        {
          input: 'isUpdateStaffNotification',
          radio: true,
          isUpdateStaffReminder: true,
        },
      ],
      [
        {
          subTitle: 'NOTIFICATIONS: CHANNELS',
          help: 'Admin users can subscribe or unsubscribe to channels of communication for different business purposes.',
        },
      ],
      [
        {
          label:
                <span>
                  <strong>Relief: </strong>
                  <span>Managing practices and filling shifts</span>
                </span>,
          radio: true,
          help: '',
        },
        {
          label:
              <span>
                <strong>Students: </strong>
                <span>Helping students, such as with externships</span>
              </span>,
          radio: true,
          help: '',
        },
      ],
      [
        {
          input: 'isShiftAdmin',
          radio: true,
        },
        {
          input: 'isExternshipAdmin',
          radio: true,
        },
      ],
    ],
    buttonTitle: 'SAVE',
  },
  {
    id: 3,
    title: 'PERSONAL INFORMATION',
    rows: [
      [
        {
          label: 'Name:',
        },
      ],
      [
        {
          input: 'name',
        },
      ],
      [
        {
          label: 'Phone Number:',
        },
        {
          label: 'Email Address:',
        },
      ],
      [
        {
          input: 'mobile',
          placeholder: '(000) 000-0000',
        },
        {
          input: 'email',
        },
      ],
    ],
    buttonTitle: 'SAVE',
  },
];

class Settings extends Component {
  constructor(props) {
    super(props);
    const { admin } = this.props;
    this.state = {
      password: '',
      password1: '',
      password2: '',
      name: admin.name || '',
      mobile: admin.mobile || '',
      email: admin.email || '',
      emailFrequency: admin.emailFrequency || emailFrequencyValues[0],
      recipients: admin.recipients ? admin.recipients.split(',') : [],
      errors: [],
      successMsg: null,
      isRealTimeEmailNotification: false,
      isRealTimeSmsNotification: false,
      isUpdateStaffNotification: false,
      help: null,
      helpMsg: null,
      isPasted: false,
      confirmDelete: false,
      isShiftAdmin: false,
      isExternshipAdmin: false,
      isHiddenUpdateStaffNotification: false,
      isDialogOpen: false,
      releaseNote: null,
      isReadReleaseNote: false,
    };
  }

  componentDidMount() {
    const { admin, actions } = this.props;
    const { releaseNote } = admin;
    actions.updateLoadingState(types.VIEW_MORE_RESOURCE_REQUESTED);
    this.setState({
      isRealTimeEmailNotification: admin?.userNotification?.isRealTimeEmailNotification,
      isRealTimeSmsNotification: admin?.userNotification?.isRealTimeSmsNotification,
      isUpdateStaffNotification: admin.isUpdateStaffNotification,
      isShiftAdmin: admin.isShiftAdmin,
      isExternshipAdmin: admin.isExternshipAdmin,
      isHiddenUpdateStaffNotification: isTPHCUser(admin),
      isReadReleaseNote: admin.isReadReleaseNote,
      releaseNote,
    });
    actions.updateLoadingState(types.VIEW_MORE_RESOURCE_SUCCESS);
  }

  handleChange = (event, key) => {
    const { isPasted } = this.state;
    let userInput = event.target.value;
    if (key == 'mobile') {
      userInput = prunePhoneNumber(userInput);
    }
    this.setState({ [key]: userInput });
  };

  validatePhone = (value) => {
    if (value.length !== 0
      && !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(value)) {
      return false;
    } else {
      return true;
    }
  };

  isDisabled = (id) => {
    const {
      password, password1, password2, recipients, name, email, mobile,
    } = this.state;

    if (id === 1) {
      return isEmpty(password1) || isEmpty(password2) || password1 !== password2;
    } else if (id === 2) {
      return false;
    }

    return isEmpty(name) || isEmpty(email) || isEmpty(mobile) || !this.validatePhone(mobile);
  };

  getErrorArray = (isValid, field, message, errorArray) => {
    if (!isValid) {
      if (!errorArray.some(error => error.field === field)) {
        errorArray.push({
          field,
          message,
        });
      }
    } else {
      errorArray = errorArray.filter(error => error.field !== field);
    }
    return errorArray;
  };

  handleOnClick = async (id) => {
    const { actions, admin, apiToken } = this.props;
    const {
      password,
      password1,
      password2,
      emailFrequency,
      recipients,
      email,
      mobile,
      name,
      errors,
      isRealTimeEmailNotification,
      isRealTimeSmsNotification,
      isUpdateStaffNotification,
      isShiftAdmin,
      isExternshipAdmin,
    } = this.state;

    if (id === 1) {
      actions.updateAdmin(apiToken, { password: password1 }, 'users', admin.id);
      const errorArray = this.state.errors.filter(error => error.field !== 'password');
      this.setState({
        password: '',
        password1: '',
        password2: '',
        errors: errorArray,
        successMsg: 'Password successfully changed',
      });
    } else if (id === 2) {
      const invalidEmailList = recipients.filter(recipient => !validateEmail(recipient.trim()));
      const isValidEmail = !recipients || (invalidEmailList.length === 0);
      let errorArray = [...errors];
      let successMsg = null;
      errorArray = this.getErrorArray(isValidEmail, 'recipients', 'Invalid email', errorArray);

      const isValidAdminLevel = isShiftAdmin || isExternshipAdmin;
      errorArray = this.getErrorArray(isValidAdminLevel, 'isShiftAdmin', 'At least one of Managing practices or Helping students should be selected', errorArray);
      if (isValidEmail && isValidAdminLevel) {
        actions.updateAdmin(apiToken, {
          emailFrequency,
          recipients: recipients.join(','),
          userNotification: { isRealTimeEmailNotification, isRealTimeSmsNotification },
          isRealTimeSmsNotification,
          isUpdateStaffNotification,
          isShiftAdmin,
          isExternshipAdmin,
        }, 'users', admin.id);
        successMsg = 'Notifications successfully updated';
      }

      this.setState({
        errors: errorArray,
        successMsg,
      });
    } else {
      const isValidEmail = validateEmail(email);
      const isValidMobile = validateMobile(mobile);
      let errorArray = [...errors];
      let successMsg = null;
      errorArray = this.getErrorArray(isValidEmail, 'email', 'Invalid email address', errorArray);
      errorArray = this.getErrorArray(isValidMobile, 'mobile', 'Invalid phone number', errorArray);

      if (isValidEmail && isValidMobile) {
        actions.updateAdmin(apiToken, {
          email: handleTrailingSpaces(email),
          name: handleTrailingSpaces(name),
          mobile,
        }, 'users', admin.id);
        successMsg = 'Personal information updated';
      }

      this.setState({
        successMsg,
        errors: errorArray,
      });
    }
  };

  clearSuccessMessage = () => {
    setTimeout(() => {
      this.setState({ successMsg: null });
    }, 4000);
  };

  handleChipDelete = (index) => {
    const temp = this.state.recipients;
    temp.splice(index, 1);
    this.setState({ recipients: temp });
  };

  renderFields = (rows, id) => {
    const {
      errors, isHiddenUpdateStaffNotification,
    } = this.state;
    return (
      <div>
        {rows.map((row, index) => {
          const subTitle = row[0] && row[0].subTitle;
          const subTitleHelp = row[0] && row[0].help;
          if (subTitle) {
            return (
              <Box
                key={`subTitleContainer_${index}`}
                sx={styles.rowNotification}
                style={{
                  width: '100%',
                  marginTop: '50px',
                }}
              >
                <Typography color="secondary" sx={styles.title} key={`subTitle_${index}`}>
                  {subTitle}
                  {subTitleHelp && (
                    <HelpOutlineIcon
                      sx={styles.subTitleHelpIcon}
                      key={`subTitleHelp_${index}`}
                      onClick={e => this.setState({
                        help: e.target,
                        helpMsg: subTitleHelp,
                      })}
                    />
                  )}
                </Typography>
              </Box>
            );
          }

          return (
            <Box key={`container_${index}`} sx={id === 2 ? styles.rowNotification : styles.row}>
              {
                row.map((input, inputIndex) => {
                  if (input.label) {
                    if (
                      input.isUpdateStaffReminder
                      && isHiddenUpdateStaffNotification
                    ) {
                      return null;
                    }
                    let labelWidth = '75%';
                    let marginLeft = 0;
                    let paddingTop = 0;

                    if (input.label === 'Recipients (Please type each email and press Enter):') {
                      marginLeft = '28px';
                      labelWidth = '50%';
                    } else if (input.label === 'Phone Number' || input.label === 'Email Address') {
                      labelWidth = '49%';
                    } else if (input.label === 'Email Frequency:') {
                      labelWidth = '25%';
                    }

                    if (input.radio) {
                      marginLeft = '16px';
                      paddingTop = '25px';
                      labelWidth = '25%';
                    }
                    return (
                      <div
                        key={`labelContainer_${inputIndex}`}
                        style={{
                          width: labelWidth,
                          marginLeft,
                          paddingTop,
                        }}
                      >
                        <Typography
                          sx={styles.label}
                          key={`label_${inputIndex}`}
                        >
                          {input.label}
                          {input.help && (
                            <HelpOutlineIcon
                              sx={styles.helpOutlineIcon}
                              key={`help_${inputIndex}`}
                              onClick={e => this.setState({
                                help: e.target,
                                helpMsg: input.help,
                              })}
                            />
                          )}
                        </Typography>
                      </div>
                    );
                  } else if (input.input === 'emailFrequency') {
                    return (
                      <Select
                        style={{ width: '25%' }}
                        key={`emailFrequency_${inputIndex}`}
                        value={this.state[input.input]}
                        onChange={e => this.handleChange(e, input.input)}
                        input={(<OutlinedInput labelwidth={0} />)}
                        sx={styles.select}
                        IconComponent={ExpandMore}
                        inputProps={{
                          styles: {
                            icon: styles.expandMoreIcon,
                          },
                        }}
                        MenuProps={{
                          getcontentanchorel: null,
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                          },
                        }}
                      >
                        {
                          emailFrequencyValues.map((value, emailIndex) => (
                            <MenuItem key={`emailFrequency_${emailIndex}`} value={value} sx={autocompleteOptionStyle}>
                              {value}
                            </MenuItem>
                          ))
                        }
                      </Select>
                    );
                  } else if (input.radio) {
                    // For TPHC admin users, hide isUpdateStaffNotification field
                    if (
                      input.input === 'isUpdateStaffNotification'
                      && isHiddenUpdateStaffNotification
                    ) {
                      return null;
                    }

                    return (
                      <div
                        key={`inputRadioContainer_${inputIndex}`}
                        style={{
                          marginLeft: '16px',
                          width: '25%',
                        }}
                      >
                        <GreenSwitch
                          key={`inputRadio_${inputIndex}`}
                          checked={this.state[input.input]}
                          disableRipple
                          onChange={e => this.setState({ [input.input]: e.target.checked })}
                        />
                        <div key={`errorTextContainer_${inputIndex}`}>
                          {errors.some(error => error.field === input.input)
                              && (
                                  <Typography
                                    color="error"
                                    key={`errorText_${inputIndex}`}
                                  >
                                    {errors[errors.findIndex(error => error.field === input.input)].message}
                                  </Typography>
                              )}
                        </div>
                      </div>
                    );
                  }

                  let style = styles.textField;

                  if (input.input === 'name') {
                    style = styles.fullWidthTextField;
                  } else if (input.input === 'recipients' || input.input === 'password2' || input.input === 'email') {
                    style = styles.textFieldContainer;
                  }

                  return (
                    <div
                      style={input.input === 'recipients' ? styles.longTextField : styles.textFieldContainer}
                      key={`textFieldContainer_${inputIndex}`}
                    >
                      {
                        input.input === 'recipients'
                          ? (
                            <MuiChipsInput
                              key={`recipients_${inputIndex}`}
                              sx={styles.chipInput}
                              variant="outlined"
                              size="medium"
                              value={this.state[input.input]}
                              onChange={val => this.setState({ [input.input]: val })}
                              onAddChip={chip => this.setState({ recipients: this.state.recipients.concat(chip) })}
                              onDeleteChip={(chip, index) => this.handleChipDelete(index)}
                            />
                          )
                          : (
                            <TextField
                              key={`textField_${inputIndex}`}
                              name={input.input}
                              value={this.state[input.input]}
                              placeholder={input.placeholder}
                              sx={style}
                              variant="outlined"
                              type={input.input === 'mobile' ? 'tel' : input.input.startsWith('password') ? 'password' : 'text'}
                              InputProps={{
                                styles: {
                                  input: styles.textFieldInput,
                                },
                              }}
                              onPaste={() => this.setState({ isPasted: true })}
                              onChange={e => this.handleChange(e, input.input)}
                            />
                          )
                      }
                      <div>
                        {errors.some(error => error.field === input.input)
                        && (
                          <Typography
                            color="error"
                          >
                            {errors[errors.findIndex(error => error.field === input.input)].message}
                          </Typography>
                        )}
                      </div>
                    </div>
                  );
                })
              }
            </Box>
          );
        })}
      </div>
    );
  };

  redirectToStripePortal() {
    const {
      actions, apiToken,
    } = this.props;
    actions.getOneResource(apiToken, 'stripe-payment/portal').then((res) => {
      const { url } = res.response;
      window.location.assign(url);
    });
  }

  cancelSubscription = () => {
    const { actions, apiToken } = this.props;
    const localAdmin = JSON.parse(localStorage.getItem('admin'));
    actions.cancelStripeSubscription(apiToken, {}, 'stripe-payment', 'aggregators',
      UsersForm.getUserAccess(localAdmin, 'aggregator').id, 'cancel')
      .then((res) => {
        if (res.response) {
          updateAdminAccessList(localAdmin, actions, res.response);
        }
        this.setState({ confirmDelete: false });
      });
  };

  onCloseDialog = () => {
    this.setState({ isDialogOpen: false });
  };

  onOpenDialog = () => {
    const {
      actions,
      apiToken,
      admin,
      history,
    } = this.props;

    this.setState({ isDialogOpen: true });

    if (!admin.isReadReleaseNote) {
      actions.updateResource(apiToken, {}, 'release-notes', 'read', this.state.releaseNote.id).then(() => {
        this.setState({ isReadReleaseNote: true });
        const localAdmin = admin;
        localAdmin.isReadReleaseNote = true;
        updateLocalAdmin(localAdmin);
        history.push('/settings');
      });
    }
  };

  render() {
    const { actions, admin } = this.props;
    const { help, helpMsg, confirmDelete } = this.state;
    let showSubscription;
    let subscriptionStatus;

    if (admin && admin.accessList && admin.accessList.length && admin.accessList.filter(x => x.stripeCustomerId).length) {
      showSubscription = true;
      subscriptionStatus = admin.accessList.filter(x => x.stripeCustomerId)[0].subscriptionStatus;
    }

    return (
      <Box>
        <img style={styles.settingImage} src={`${IMG_STORAGE_LINK}search-dog-s-2.png`} />
        <Box sx={styles.container}>
          <Typography sx={{ ...styles.main, paddingTop: { xs: '30px', md: 'inherit' } }}>
            <img style={styles.settingLogo} src={`${IMG_STORAGE_LINK}settings.png`} />
            SETTINGS
            {this.state.releaseNote && (
              <span style={styles.subTitle} onClick={this.onOpenDialog}>
                Current Version:
                {' '}
                {this.state.releaseNote.version}
                {!this.state.isReadReleaseNote && <Icon sx={styles.animatedIcon}>lens</Icon>}
              </span>
            )}
          </Typography>
          <ReleaseNoteDialog
            isDialogOpen={this.state.isDialogOpen}
            onCloseDialog={this.onCloseDialog}
            content={this.state.releaseNote}
          />
          {showSubscription && (
            <Card sx={styles.card}>
              <CardContent sx={styles.cardContent}>
                <Typography color="secondary" sx={styles.title}>
                  SUBSCRIPTION INFORMATION
                </Typography>
                <div style={styles.row}>
                  <Typography sx={styles.label}>
                    Status:
                  </Typography>
                </div>
                <div style={styles.row}>
                  <Typography sx={styles.info}>
                    {subscriptionStatus}
                  </Typography>
                </div>
                <div style={styles.buttonRow}>
                  <Button
                    sx={styles.button}
                    onClick={() => this.redirectToStripePortal()}
                  >
                    View Information
                  </Button>
                  {subscriptionStatus === 'active'
                  && (
                    <Button
                      sx={styles.buttonRight}
                      onClick={() => this.setState({ confirmDelete: true })}
                    >
                      Cancel Subscription
                    </Button>
                  )
                  }
                </div>
              </CardContent>
            </Card>
          )
          }
          {
            cards.map(card => (
              <Card key={card.id} sx={styles.card}>
                <CardContent sx={styles.cardContent}>
                  <Typography color="secondary" sx={styles.title}>
                    {card.title}
                  </Typography>
                  {this.renderFields(card.rows, card.id)}
                  <Button
                    sx={styles.button}
                    disabled={this.isDisabled(card.id)}
                    onClick={() => this.handleOnClick(card.id)}
                    key={`btn_${card.id}`}
                  >
                    {card.buttonTitle}
                  </Button>
                </CardContent>
              </Card>
            ))
          }
          <Popover
            open={!!help}
            anchorEl={help}
            onClose={e => this.setState({ help: null })}
          >
            <Paper sx={styles.helpPopover}>
              <Typography sx={styles.helpPopoverText}>{helpMsg}</Typography>
            </Paper>
          </Popover>
          {this.state.successMsg !== null
          && (
            <MessageSnackbar
              openMessageSnackbar={this.state.successMsg !== null}
              successMsg={this.state.successMsg}
              actions={actions}
              clearSuccessMessage={() => this.clearSuccessMessage()}
            />
          )
          }
          {confirmDelete
          && (
            <DeleteConfirmDialog
              resourceName="test"
              isDialogOpen={confirmDelete}
              description='You are about to cancel your subscription, some portions of the Jobs Unleashed Platform will no longer be available.'
              buttonText='Cancel Subscription'
              onCloseDialog={() => this.setState({ confirmDelete: false })}
              onClickConfirmButton={this.cancelSubscription}
            />
          )
          }
        </Box>
      </Box>
    );
  }
}

export default withRouter(Settings);
