import React, { useState, useEffect } from 'react';
import { Paper, Typography, IconButton, Box } from '@mui/material';
import moment from 'moment';
import {
  isNumber,
  isEmpty,
  isNil,
  sortBy,
  difference,
} from 'lodash';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';


import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';


import { IMG_STORAGE_LINK } from '../../data/Constants';
import { orange, navy, errorRed, green, gray } from '../../css/style';
import { fontSize, fontWeight } from '@mui/system';

const msgDateFormat = 'MM/DD/YY, HH:mm';

const styles = {
  messageBubble: {
    maxWidth: '80%',
    fontFamily: 'Lato',
    borderRadius: '15px',
    padding: '2px 20px',
    minHeight: '40px',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'break-spaces',
  },
  receiveMessageContainer: { display: 'flex', flexDirection: 'row' },
  messageUnread: {
    width: '30px',
    height: '30px',
    fontFamily: 'Lato',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '22px',
    backgroundColor: '#4bbdad',
    color: '#ffffff',
    boxShadow: 'unset',
    position: 'relative',
    marginTop: '5px',
    cursor: 'pointer',
    minWidth: '30px',
    marginRight: '10px',
  },
  unreadImg: {
    width: '15px', margin: 'auto',
  },
  unreadTxt: {
    position: 'absolute',
    top: '31px',
    width: '36px',
    height: '20px',
    fontFamily: 'Asap',
    fontSize: '7px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#ababab',
  },
  dateFuncIcon: {
    width: '24px',
    height: '24px',
    cursor: 'pointer',
  },
  dateText: {
    fontSize: '16px',
    color: navy,
    fontWeight: '600',
    fontFamiFy: 'Asap',
  },
};
const oldPingedSign = '$$pinged$$';
const pingedSign = '$$has pinged$$';
const leftSign = '$$has left the chat.$$';

const MessageBubble = (props) => {
  const {
    content, position, type, date, senderName, isUnread, messageId, onClickSetUnreadMessage, unreadMessages, info,
  } = props;
  const [hover, setHover] = useState(false);
  const [marked, setMarked] = useState(false);
  const realUnread = isUnread && !marked;
  let actionTextSign = null;
  if (content) {
    if (content.includes(pingedSign)) {
      actionTextSign = pingedSign;
    } else if (content.includes(oldPingedSign)) {
      actionTextSign = oldPingedSign;
    } else if (content.includes(leftSign)) {
      actionTextSign = leftSign;
    }
  }
  const renderDateDisplay = () => {
    const { handleDateMsgConfirm, messageId, requestTrackingDates } = props;

    if (!isEmpty(requestTrackingDates)) {
      let filterDates = requestTrackingDates.filter(row => row.message_id === messageId);
      filterDates = sortBy(filterDates, 'date');

      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {filterDates.map((row, index) => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                minWidth: '80px',
              }}
            >
              <span style={styles.dateText}>{moment(row.date).format('MM/DD')}</span>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  '& .MuiButtonBase-root': {
                    padding: '0 4px',
                  },
                }}
                key={`${index}_date_div`}
              >
                {(row.status === 'requested' || row.status === 'approved')
                  && (
                  <IconButton
                    key={`approve-btn-${index}`}
                    onClick={() => {
                      if (row.status !== 'requested') return;
                      handleDateMsgConfirm(row, 'approved');
                    }}
                  >
                    <CheckCircleIcon
                      key={`approve-icon-${index}`}
                      sx={{ ...styles.dateFuncIcon, color: green }}
                    />
                  </IconButton>
                  )}
                {(row.status === 'requested' || row.status === 'declined')
                  && (
                  <IconButton
                    key={`decline-btn-${index}`}
                    onClick={() => {
                      if (row.status !== 'requested') return;
                      handleDateMsgConfirm(row, 'declined');
                    }}
                  >
                    <CancelIcon
                      key={`decline-icon-${index}`}
                      sx={{ ...styles.dateFuncIcon, color: errorRed }}
                    />
                  </IconButton>
                  )}
              </Box>
            </div>
          ))}
        </div>
      );
    }
    return '';
  };

  const actionText = actionTextSign ? actionTextSign.replace(/\$/g, '') : '';
  const contentArr = actionTextSign ? content.split(actionTextSign) : [];

  return (
    (content && actionTextSign && content.includes(actionTextSign))
      ? (
                <div style={{
                  minHeight: '50px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  paddingLeft: '30px',
                }}
                >
                    <Typography style={{
                      padding: '5px 15px 5px 15px',
                      fontFamily: 'Asap',
                      fontSize: '16px',
                      color: `${orange}`,
                      position: 'relative',
                    }}
                    >
                      { content.includes(leftSign)
                        ? (
                        <CancelScheduleSendIcon
                          style={{
                            color: `${orange}`,
                            position: 'absolute',
                            top: '5px',
                          }}
                        />
                        )
                        : (
                          <NotificationsActiveIcon
                            style={{
                              color: `${orange}`,
                              position: 'absolute',
                              top: '5px',
                            }}
                          />
                        )}
                        {contentArr.length
                          && <span style={{ paddingLeft: '30px', fontWeight: 'bold' }}>{contentArr[0]}</span>
                        }
                        <span style={{ fontWeight: 'bold' }}>
                          {actionText}
                        </span>
                        {contentArr.length > 1
                          && (
                          <span style={{ fontWeight: 'bold' }}>
                            {contentArr[1]}
                          </span>
                          )
                        }
                    </Typography>

                    {info && (
                      <Typography style={{
                        padding: '5px 15px 5px 15px',
                        color: `${navy}`,
                        fontSize: '12px',
                      }}
                      >
                        {info}
                      </Typography>
                    )}

                    <Typography style={{
                      padding: '5px 15px 5px 15px',
                      color: '#909090',
                      fontSize: '12px',
                    }}
                    >
                        {moment(date).calendar()}
                    </Typography>
                </div>
      )
      : (
                <div
                  style={{
                    minHeight: '50px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: type === 'sender' ? 'flex-end' : 'flex-start',
                    paddingLeft: type === 'receiver' && '30px',
                    paddingRight: type === 'sender' && '30px',
                  }}
                >
                    {type == 'receiver'
                      ? (
                    <div style={styles.receiveMessageContainer}>
                        <Paper
                          style={{
                            ...styles.messageBubble,
                            backgroundColor: '#ffffff',
                            color: '#000000',
                            border: marked ? '1px solid #4bbdad' : 'unset',
                            boxShadow: '0 8px 24px 0 rgba(0, 0, 0, 0.1)',
                            whiteSpace: 'inherit',
                          }}
                          onMouseEnter={() => {
                            setHover(true);
                          }}
                          onMouseLeave={() => {
                            setHover(false);
                          }}
                        >
                          {content}
                        </Paper>
                        { realUnread && (
                            <Paper
                              style={styles.messageUnread}
                              onClick={() => {
                                setMarked(true);
                                onClickSetUnreadMessage(messageId);
                              }}
                            >
                                <img src={`${IMG_STORAGE_LINK}email_icon.svg`} alt="Email" style={styles.unreadImg} />
                                <span style={styles.unreadTxt}>
                                    MARK AS
                                    UNREAD
                                </span>
                        </Paper>
                        )
                        }

                        <div>
                            {renderDateDisplay()}
                        </div>

                    </div>
                      )
                      : (
                            <Paper
                              style={{
                                ...styles.messageBubble,
                                backgroundColor: '#243060',
                                color: '#ffffff',
                                boxShadow: '0 8px 24px 0 rgba(0, 0, 0, 0.1)',
                              }}
                            >
                                {content}
                            </Paper>
                      )}
                    <Typography style={{
                      padding: '5px 15px 5px 15px',
                      color: '#909090',
                      fontSize: '12px',
                    }}
                    >
                        {moment(date).format(msgDateFormat)}
                        {type === 'sender'
                        && ` - ${senderName}`
                        }
                    </Typography>
                </div>
      )
  );
};

export default MessageBubble;
