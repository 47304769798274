/* eslint-disable object-curly-newline */
export const resourceFields = {
  jobPostings: [
    {
      key: 'title',
      title: 'Name',
      gridWidth: '6',
      width: '25%',
    },
    {
      key: 'typeOfEmployment',
      title: 'Type',
      width: '15%',
    },
    {
      key: 'practice.name',
      title: 'Practice Name',
      width: '25%',
      minWidth: '105px',
    },
  ],
  applications: [
    {
      key: 'talentTypeIcon',
      title: '',
      width: '1%',
    },
    {
      key: 'fullName',
      title: 'Name',
      gridWidth: '6',
      width: '15%',
    },
    {
      key: 'practiceName',
      title: 'Practice Name',
      width: '15%',
    },
    {
      key: 'jobTitle',
      title: 'Post Title',
      width: '40%',
    },
    {
      key: 'status',
      title: '',
      width: '15%',
    },
    {
      key: 'contactedAt',
      title: 'contact time',
      width: '14%',
      sort: 'connect_date',
      toolTip: 'The face indicates the speed of responses to new messages and applications.',
    },
  ],
  connections: [
    {
      key: 'talentTypeIcon',
      title: '',
      padding: '16px 10px 16px 20px',
    },
    {
      key: 'fullName',
      title: 'Name',
      gridWidth: '6',
      width: '25%',
    },
    {
      key: 'jobPosting.title',
      title: 'Position Post',
      width: '20%',
    },
    {
      key: 'talentType',
      title: 'Employee Type',
      width: '30%',
      minWidth: '105px',
    },
  ],
  practices: [
    {
      key: 'name',
      title: 'Name',
      icon: 'swap_vert',
      width: '20%',
    },
    {
      key: 'address',
      title: 'Address',
      width: '20%',
    },
    {
      key: 'positions',
      title: 'Relief Need Stats',
      width: '20%',
    },
    {
      key: 'waitingMsg',
      title: '',
      width: '5%',
      disableClick: true,
    },
    {
      key: 'active',
      title: 'Active',
      width: '5%',
      disableClick: true,
    },
  ],
  badges: [
    {
      key: 'name',
      title: 'Name',
    },
    {
      key: 'email',
      title: 'Email',
    },
    {
      key: 'badges',
      title: 'Badges',
    },
    {
      key: 'actions',
      title: 'Actions',
      disableClick: true,
    },
  ],
  users: [
    {
      key: 'name',
      title: 'Name',
      gridWidth: '6',
      width: '20%',

    },
    {
      key: 'email',
      title: 'Email',
      gridWidth: '6',
      width: '25%',
    },
    {
      key: 'practice',
      title: 'Practice',
      gridWidth: '6',
      width: '20%',
    },
    {
      key: 'location',
      title: 'Location',
      width: '15%',
    },
    {
      key: 'role',
      title: 'Admin Level',
      width: '10%',
    },
    {
      key: 'active',
      title: 'Active',
      width: '5%',
      disableClick: true,
    },
    {
      key: 'actions',
      title: 'Actions',
      width: '5%',
    },
  ],
  privateUsers: [
    {
      key: 'talentTypeIcon',
      title: '',
      width: '1%',
    }, {
      key: 'name',
      title: 'Name',
      width: '30%',
    },
    {
      key: 'category',
      title: 'Job Category',
      width: '20%',
    },
    {
      key: 'homePractice',
      title: 'Home Practice',
      width: '30%',
    },
    {
      key: 'active',
      title: 'Active',
      width: '5%',
      disableClick: true,
    },
    {
      key: 'actions',
      title: 'Actions',
      width: '19%',
    },
  ],
};
export const sevenDaysInAWeek = {
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
  SUNDAY: 'Sunday',
};
// Todo: limited color size, better to generate dynamically
export const colors = ['#3bcb95', '#fa7756', '#97afe2', '#58b6e7', '#9f337e', '#f2b471', '#c47589', '#6b97fe', '#9576bc', '#ebc22f', '#114255', '#2da912', '#be1b27'];
export const customColors = ['#EF3924', '#F16122', '#FDB614'];
// static for now without login for admins
// in the future we'll need to update this dynamically
export const companyHeader = {
  'Content-Type': 'application/json',
  Authorization: 'Bearer f572bbbe-5104-4ed7-8934-afdd8a5e3d62',
};
/**
 * Allows users to specify custom options for a MultiSelect dropdown
 * Append: Append the key & the title { key: 'ABVP', title: 'Beef Cattle' } -> ABVP - Beef Cattle
 * Sort: Sorts the list based on alphabetical order
 * CapitalizeKey: Transform text to use capital letters
 * UseLabel: Provide an alternate label to replace key
 * CapitalizeTitle: Display the title in Capital Case
 */
export const customLabelConfig = {
  speciality: {
    append: true,
    sort: true,
    capitalizeKey: true,
    useLabel: true,
    capitalizeTitle: true,
  },
  'vet-certification': {
    append: true,
  },
  'cert-specialty': {
    append: true,
    sort: true,
    capitalizeKey: true,
  },
  'rotation-types': {
    useLabel: true,
    sort: true,
  },
};

export const userPaginationCount = 10;
export const matchesPaginationCount = 10;
export const connectionsPaginationCount = 5;
export const practicesPaginationCount = 10;
export const jobPostingsPaginationCount = 12;

export const editorApiKey = '5eptpqx17h0b2ikp0vxnfhab27zdzcombobni69e59sqpo6i';
export const EMPLOYMENT_TYPES = ['Full Time', 'Part Time', 'Relief', 'Shadowing', 'Externship'];
// export const EMPLOYMENT_TYPES = ['Full Time', 'Part Time', 'Locum', 'Relief', 'Bulletin Board', 'Internship' ,'Externship' , 'Ambulatory', 'PRN - As Needed Employee'];
export const EMPLOYMENT_TYPES_OBJ = EMPLOYMENT_TYPES.map(item => ({
  key: item,
  title: item,
}));

export function filterJobTypes(jobTypes, admin, isEdit) {
  const { isShiftAdmin, isExternshipAdmin } = admin;
  const jobTypeFiltered = isEdit
    ? jobTypes
    : jobTypes.filter(item => !['Full Time', 'Part Time'].includes(item.key)); // filter out Full-time & Part-time for create new / duplidate
  return jobTypeFiltered.filter((item) => {
    if (item.key === 'Externship' || item.key === 'Shadowing') return isExternshipAdmin;
    return isShiftAdmin;
  });
}

export const EXPERIENCE_LEVELS = ['Junior', 'Intermediate', 'Senior'];
export const EXPERIENCE_LEVELS_OBJ = EXPERIENCE_LEVELS.map(item => ({
  key: item,
  title: item,
}));

// The vet tech has two more experience level options than vet assistant
export const EXPERIENCE_LEVEL_VET_TECH_SPECIAL = {
  'vet-certification': true,
  'cert-specialty': true,
};

export const EXPERIENCE_LEVELS_CONFIG = {
  'vet-tech-or-assistant': {
    'vet-sub-category': true,
    'job-type-sub-category': true,
    ...EXPERIENCE_LEVEL_VET_TECH_SPECIAL,
    'patient-type': true,
    'years-in-position': true,
    'practice-size': true,
    'patients-per-day': true,
    'administering-anesthesia': true,
    'monitoring-anesthesia': true,
    'central-line': true,
    catheterization: true,
    'filling-presciptions': true,
    Junior: {
      'years-in-position': 0,
      'administering-anesthesia': 2,
      'monitoring-anesthesia': 2,
      'central-line': 2,
      catheterization: 2,
      'filling-presciptions': 2,
    },
    Intermediate: {
      'years-in-position': 3,
      'administering-anesthesia': 3,
      'monitoring-anesthesia': 3,
      'central-line': 3,
      catheterization: 3,
      'filling-presciptions': 3,
    },
    Senior: {
      'years-in-position': 7,
      'administering-anesthesia': 4,
      'monitoring-anesthesia': 4,
      'central-line': 4,
      catheterization: 4,
      'filling-presciptions': 4,
    },
  },
  'specialist-veterinarian': {
    'patient-type': true,
    speciality: true,
    'job-type-sub-category': true,
    'years-in-position': true,
    'practice-size': true,
    'patients-per-day': true,
    'certification-status': true,
  },
  'dvm-doctor-of-veterinary-medicine': {
    'sub-category': true,
    'job-type-sub-category': true,
    'patient-type': true,
    'years-in-position': true,
    'practice-size': true,
    'patients-per-day': true,
    Junior: {
      'years-in-position': 0,
    },
    Intermediate: {
      'years-in-position': 3,
    },
    Senior: {
      'years-in-position': 7,
    },
  },
  'non-clinical': {
    'job-category': true,
    'years-in-animal-care': true,
    'practice-size': true,
    Junior: {
      'years-in-animal-care': 0,
    },
    Intermediate: {
      'years-in-animal-care': 3,
    },
    Senior: {
      'years-in-animal-care': 7,
    },
  },
};

export const TALENT_ROLES_CONFIG = {
  'vet-tech-or-assistant': {
    subKey: 'vetSubCategory',
    dataSubKey: 'vet-sub-category',
    title: 'Vet Tech / Vet Assistant',
  },
  'specialist-veterinarian': {
    subKey: 'specialty',
    dataSubKey: 'speciality',
    title: 'Specialist Veterinarian',
  },
  'dvm-doctor-of-veterinary-medicine': {
    subKey: 'subCategory',
    dataSubKey: 'sub-category',
    title: 'DVM - Doctor of Veterinary Medicine',
  },
  'non-clinical': {
    subKey: 'jobCategory',
    dataSubKey: 'job-category',
    title: 'Non-Clinical',
  },
};

export const VET_TYPE_VET_TECH_OR_ASSISTANT = {
  key: 'vet-tech-or-assistant',
  title: 'Vet Tech / Vet Assistant',
};
export const VET_TYPE_SPECIALIST_VETERINARIAN = {
  key: 'specialist-veterinarian',
  title: 'Specialist Veterinarian',
};
export const VET_TYPE_NON_CLINICAL = {
  key: 'non-clinical',
  title: 'Non-Clinical',
};
export const VET_TYPE_DVM = {
  key: 'dvm-doctor-of-veterinary-medicine',
  title: 'DVM - Doctor of Veterinary Medicine',
};
export const VET_TYPES = [VET_TYPE_VET_TECH_OR_ASSISTANT, VET_TYPE_SPECIALIST_VETERINARIAN, VET_TYPE_DVM, VET_TYPE_NON_CLINICAL];
export const VET_TYPES_TITLES = VET_TYPES.reduce((o, i) => {
  o[i.key] = i.title;
  return o;
}, {});
export const HQ_ADMIN = 'HQ Admin';
export const REGIONAL_ADMIN = 'Regional Admin';
export const PRACTICE_ADMIN = 'Practice Admin';
export const ADMIN_NO_PRACTICE = 'Admin without Practice';
export const HQ_ADMIN_KEY = 'HQ_ADMIN';
export const REGIONAL_ADMIN_KEY = 'REGIONAL_ADMIN';
export const PRACTICE_ADMIN_KEY = 'PRACTICE_ADMIN';
export const ADMIN_NO_PRACTICE_KEY = 'ADMIN_NO_PRACTICE';
export const REGIONAL_ADMIN_ROLE = 'HQ/Regional Admin';
export const PRACTICE_ADMIN_ROLE = 'HQ/Practice Admin';
export const ROLES = [HQ_ADMIN, REGIONAL_ADMIN, PRACTICE_ADMIN];
export const USER_ROLES = [
  { key: HQ_ADMIN_KEY, title: HQ_ADMIN },
  { key: REGIONAL_ADMIN_KEY, title: REGIONAL_ADMIN },
  { key: PRACTICE_ADMIN_KEY, title: PRACTICE_ADMIN },
  { key: ADMIN_NO_PRACTICE_KEY, title: ADMIN_NO_PRACTICE },
];
export const STATUSES = ['Active', 'Inactive'];
export const USER_STATUSES = STATUSES.map(item => ({
  key: item,
  title: item,
}));
export const EXTERNSHIP = 'Externship';
export const days = [
  {
    key: 'SUNDAY',
    title: 'Su',
  },
  {
    key: 'MONDAY',
    title: 'Mo',
  },
  {
    key: 'TUESDAY',
    title: 'Tu',
  },
  {
    key: 'WEDNESDAY',
    title: 'We',
  },
  {
    key: 'THURSDAY',
    title: 'Th',
  },
  {
    key: 'FRIDAY',
    title: 'Fr',
  },
  {
    key: 'SATURDAY',
    title: 'Sa',
  },
];

export const timeLines = [
  {
    key: 'all',
    name: 'All',
    id: 1,
    millisecond: 0,
  },
  {
    key: 'oneWeek',
    name: '1 Week',
    id: 2,
    millisecond: 604800,
  },
  {
    key: 'twoWeeks',
    name: '2 Weeks',
    id: 3,
    millisecond: 1209600,
  },
  {
    key: 'thirtyDays',
    name: '30 days',
    id: 4,
    millisecond: 2592000,
  },
  {
    key: 'sixtyDays',
    name: '60 days',
    id: 5,
    millisecond: 5184000,
  },
  {
    key: 'ninetyDays',
    name: '90 days',
    id: 6,
    millisecond: 7776000,
  },
];

export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const reportFrequency = [
  {
    name: 'None',
    value: 'NONE',
    id: 1,
  },
  {
    name: 'Daily',
    value: 'DAILY',
    id: 2,
  },
  {
    name: 'Weekly',
    value: 'WEEKLY',
    id: 3,
  },
  {
    name: 'Monthly',
    value: 'MONTHLY',
    id: 4,
  },
];


export const pieChartOptions = {
  cutoutPercentage: 75,
  tooltips: {
    enabled: false,
  },
};

export const pieChartLegend = {
  display: false,
};

export const lineChartOptions = {
  scales: {
    y: {
      ticks: {
        // use max and min to set the range of y-axis
        // max: 100,
        // min: 0,
        stepSize: 1,
      },
    },
  },
};
export const CERTIFICATION_STATUS_TYPES = [
  {
    key: 'board-certified',
    title: 'Board Certified',
  },
  {
    key: 'practice-limited',
    title: 'Practice Limited',
  },
];
export const ANIMAL_PATIENT_TYPES = [{
  key: 'companion',
  title: 'Companion',
}, {
  key: 'exotic-companion',
  title: 'Exotic Companion',
}, {
  key: 'equine',
  title: 'Equine',
}, {
  key: 'large-food',
  title: 'Large Food',
}, {
  key: 'small-food',
  title: 'Small Food',
}, {
  key: 'lab',
  title: 'Lab',
}, {
  key: 'wildlife',
  title: 'Wildlife',
}];

export const VET_CERT_SPECIALTIES = [
  {
    key: 'avdt',
    title: 'Dentistry',
  }, {
    key: 'avtaa',
    title: 'Anesthesia',
  }, {
    key: 'aimvt',
    title: 'Internal Medicine',
  }, {
    key: 'avecct',
    title: 'Emergency / Critical Care',
  }, {
    key: 'abvt',
    title: 'Behaviour',
  }, {
    key: 'avzmt',
    title: 'Zoological Medicine',
  }, {
    key: 'aevnt',
    title: 'Equine',
  }, {
    key: 'avtcp',
    title: 'Clinical Practice',
  }, {
    key: 'avst',
    title: 'Surgery',
  }, {
    key: 'avnt',
    title: 'Nutrition',
  }, {
    key: 'avot',
    title: 'Opthalmic',
  }, {
    key: 'avcpt',
    title: 'Clinical Pathology',
  }, {
    key: 'aala',
    title: 'Lab Animal',
  }, {
    key: 'aav',
    title: 'Avian',
  }, {
    key: 'acvr',
    title: 'Radiology',
  }, {
    key: 'aarv',
    title: 'Marine Rehabilitation',
  }];
export const CERTIFICATIONS = [
  {
    key: 'LVT',
    title: 'Licensed Veterinary Technician',
  },
  {
    key: 'CVT',
    title: 'Certified Veterinary Technician',
  },
  {
    key: 'RVT',
    title: 'Registered Veterinary Technician',
  },
  {
    key: 'LVMT',
    title: 'Licensed Veterinary Medical Technican',
  },
  {
    key: 'CT / UT No Cert',
    title: 'Other / None',
  },
];
export const SPECIALITIES = ['shelter medicine', 'reptile medicine', 'exotic companion animal', 'canine and feline', 'equine', 'food animal', 'dairy', 'swine health', 'avian', 'beef cattle', 'feline', 'toxicology', 'animal welfare', 'laboratory animal medicine', 'poultry', 'theriogeniology', 'anesthesia', 'behavior', 'pharmacology', 'dermatology', 'emergency and critical care', 'cardiology', 'small animal internal medicine', 'large animal internal medicine', 'neurology', 'oncology', 'virology', 'immunology', 'bacteriology/mycology', 'parisitology', 'nutrition', 'ophthalmology', 'anatomic pathology', 'clinical pathology', 'preventative medicine', 'radiology', 'radiation oncology', 'equine diagnostic imaging', 'small animal surgery', 'large animal surgery', 'canine', 'zoological medicine', 'dentistry', 'equine dental'];
export const SPECIALTIES_BY_COLLEGE = {
  ABVP: [
    'shelter medicine',
    'reptile medicine',
    'exotic companion animal',
    'canine and feline',
    'equine',
    'food animal',
    'dairy',
    'swine health',
    'avian',
    'beef cattle',
    'feline',
  ],
  ABVT: [
    'toxicology',
  ],
  ACAW: [
    'animal welfare',
  ],
  ACLAM: [
    'laboratory animal medicine',
  ],
  ACPV: [
    'poultry',
  ],
  ACT: [
    'theriogeniology',
  ],
  ACVAA: [
    'anesthesia',
  ],
  ACVB: [
    'behavior',
  ],
  ACVCP: [
    'pharmacology',
  ],
  ACVD: [
    'dermatology',
  ],
  ACVECC: [
    'emergency and critical care',
  ],
  ACVIM: [
    'cardiology',
    'small animal internal medicine',
    'large animal internal medicine',
    'neurology',
    'oncology',
  ],
  ACVM: [
    'virology',
    'immunology',
    'bacteriology/mycology',
    'parisitology',
  ],
  ACVN: [
    'nutrition',
  ],
  ACVO: [
    'ophthalmology',
  ],
  ACVP: [
    'anatomic pathology',
    'clinical pathology',
  ],
  ACVPM: [
    'preventative medicine',
  ],
  ACVR: [
    'radiology',
    'radiation oncology',
    'equine diagnostic imaging',
  ],
  ACVS: [
    'small animal surgery',
    'large animal surgery',
  ],
  ACVSMR: [
    'canine',
    'equine',
  ],
  ACZM: [
    'zoological medicine',
  ],
  AVDC: [
    'dentistry',
    'equine dental',
  ],
};

// we can improve this in the future by making something < n^2
// O(n) for each specialty O(n) for each search
export const SPECIALITIES_MAP = SPECIALITIES.map(item => ({
  key: item,
  title: `${item.charAt(0).toUpperCase()}${item.slice(1)}`,
  label: Object.keys(SPECIALTIES_BY_COLLEGE).filter(key => SPECIALTIES_BY_COLLEGE[key].includes(item))[0],
}));

export const DVM_CATEGORIES = [
  {
    key: 'all',
    title: 'Both GP and ER DVMs',
  },
  {
    key: 'gp-veterinarian',
    title: 'GP Veterinarian',
  },
  {
    key: 'er-veterinarian',
    title: 'ER Veterinarian',
  },
  {
    key: 'telemedicine-vet',
    title: 'Telemedicine Veterinarian',
  },
];

export const DVM_STAFF_CATEGORIES = [
  {
    key: 'all',
    title: 'Both GP and ER DVMs',
  },
  {
    key: 'gp-veterinarian',
    title: 'GP Veterinarian',
  },
  {
    key: 'er-veterinarian',
    title: 'ER Veterinarian',
  },
];

export const TELEMEDICINE_CATEGORIES = [
  {
    key: 'telemedicine-vet',
    title: 'Telemedicine',
  },
  {
    key: 'in-person',
    title: 'In-person',
  },
  {
    key: 'telemedicine-in-person',
    title: 'In-Person and Telemed.',
  },
];

export const VET_CATEGORIES = [
  {
    key: 'all',
    title: 'Both Vet Tech and Vet Assistant',
  },
  {
    key: 'certified-vet-tech',
    title: 'Vet Tech',
  },
  {
    key: 'vet-assistant',
    title: 'Vet Assistant',
  },
  {
    key: 'telemedicine-vet',
    title: 'Telemedicine',
  },
];

export const SPECIALIST_TELEMED_JOB_OPTIONS = [
  {
    key: 'in-person',
    title: 'In-Person Medicine',
  },
  {
    key: 'telemedicine-vet',
    title: 'Telemedicine',
  },
];

export const VET_STAFF_CATEGORIES = [
  {
    key: 'all',
    title: 'Both Vet Tech and Vet Assistant',
  },
  {
    key: 'certified-vet-tech',
    title: 'Vet Tech',
  },
  {
    key: 'vet-assistant',
    title: 'Vet Assistant',
  },
];

export const JOB_CATEGORIES = [
  {
    key: 'accounting',
    title: 'Accounting',
  },
  {
    key: 'finance',
    title: 'Finance',
  },
  {
    key: 'human-resources',
    title: 'Human Resources',
  },
  {
    key: 'information-technology',
    title: 'Information Technology',
  },
  {
    key: 'sales',
    title: 'Sales',
  },
  {
    key: 'marketing',
    title: 'Marketing',
  },
  {
    key: 'practice-management',
    title: 'Practice Management',
  },
  {
    key: 'supply-chain',
    title: 'Supply Chain',
  },
  {
    key: 'administrative',
    title: 'Administrative',
  },
  {
    key: 'customer-service',
    title: 'Customer Service / Client Care',
  },
  {
    key: 'grooming',
    title: 'Grooming',
  },
  {
    key: 'kennel-tech',
    title: 'Kennel Tech',
  },
  {
    key: 'dog-handler',
    title: 'Dog Handler',
  },
  {
    key: 'custodian',
    title: 'Custodian',
  },
  {
    key: 'dog-trainer',
    title: 'Dog Trainer',
  },
  {
    key: 'kennel-boarding-manager',
    title: 'Kennel Boarding Manager',
  },
  {
    key: 'facilities-coordinator',
    title: 'Facilities Coordinator',
  },
  {
    key: 'other',
    title: 'Other',
  },
];
export const EXPERIENCE_TYPE_CONFIG = {
  speciality: {
    title: 'Specialty',
    unselected: 'Please Select',
    items: SPECIALITIES_MAP,
  },
  'sub-category': {
    title: 'Sub-category',
    unselected: 'Please Select',
    items: DVM_CATEGORIES,
  },
  'vet-sub-category': {
    title: 'Sub-category',
    unselected: 'Please Select',
    items: VET_CATEGORIES,
  },
  'job-category': {
    title: 'Job Category',
    unselected: 'Please Select',
    items: JOB_CATEGORIES,
  },
  'vet-certification': {
    title: 'Certification',
    unselected: 'Any Certification',
    items: CERTIFICATIONS,
  },
  'cert-specialty': {
    title: 'Specialty',
    unselected: 'Any Specialty',
    items: VET_CERT_SPECIALTIES,
  },
  'patient-type': {
    title: 'Animal Types',
    inputTitle: 'Candidate require familiarity with these animal types',
    unselected: 'All Animal Types',
    items: ANIMAL_PATIENT_TYPES,
  },
  'school-name': {
    title: 'School Name',
    isStudent: true,
  },
  'school-id': {
    title: 'School ID',
    isStudent: true,
  },
  'graduation-date': {
    title: 'Graduation Date',
    isStudent: true,
  },
  'extern-discipline-1': {
    title: 'Extern Discipline 1',
    isStudent: true,
  },
  'extern-discipline-2': {
    title: 'Extern Discipline 2',
    isStudent: true,
  },
  'extern-career': {
    title: 'Extern Career',
    isStudent: true,
  },
  'years-in-animal-care': {
    title: 'Years in Animal Care',
    default: 0,
    max: 20,
  },
  'years-in-position': {
    title: 'Years in current position',
    default: 0,
    max: 20,
  },
  'practice-size': {
    title: 'Practice Size',
    inputTitle: 'Candidates minimum practice size',
    min: 0,
    max: 20,
    tier3: true,
  },
  'certification-status': {
    title: 'Certification Status',
    unselected: 'Any Certification',
    items: CERTIFICATION_STATUS_TYPES,
  },
  'patients-per-day': {
    title: 'Patients Per Day',
    inputTitle: 'Candidates minimum # of patients/day',
    min: 0,
    max: 20,
    tier3: true,
  },
  'administering-anesthesia': {
    title: 'Administering Anesthesia',
    min: 1,
    max: 5,
    tier3: true,
  },
  'monitoring-anesthesia': {
    title: 'Monitoring Anesthesia',
    min: 1,
    max: 5,
    tier3: true,
  },
  'central-line': {
    title: 'Running a central line',
    min: 1,
    max: 5,
    tier3: true,
  },
  catheterization: {
    title: 'Catheterization',
    min: 1,
    max: 5,
    tier3: true,
  },
  'filling-presciptions': {
    title: 'Filling Prescriptions',
    min: 1,
    max: 5,
    tier3: true,
  },
};

export const TALENT_PROFILE_ROLE_MAPPING = {
  speciality: {
    items: SPECIALITIES_MAP,
  },
  specialty: {
    items: SPECIALITIES_MAP,
  },
  'sub-category': {
    items: DVM_CATEGORIES,
  },
  'vet-sub-category': {
    items: VET_CATEGORIES,
  },
  'job-category': {
    items: JOB_CATEGORIES,
  },
};

/**
 * This stores information about the fields that are 'above the fold'
 * and need to be hidden from the talent experience box
 */
export const EXPERIENCE_CATEGORY_SPECIAL_FIELD_CONFIG = {
  'non-clinical': {
    key: 'job-category',
    title: 'Job Category',
    items: JOB_CATEGORIES,
  },
  'specialist-veterinarian': {
    key: 'speciality',
    title: 'Specialty',
    items: SPECIALITIES_MAP,
  },
  'dvm-doctor-of-veterinary-medicine': {
    key: 'sub-category',
    title: 'Sub-category',
    items: DVM_CATEGORIES,
  },
  'vet-tech-or-assistant': {
    key: 'vet-sub-category',
    title: 'Sub-category',
    items: VET_CATEGORIES,
  },
};

export const socialMediaValues = [
  'Facebook', 'Twitter', 'Instagram', 'YouTube',
];

export const emailFrequencyValues = [
  'Daily', 'Weekly', 'Monthly',
];

export const PATHWAY_AGGREGATOR_ID = 6;

export const PING_TEXTFIELD_TOOLTIP = 'Be brief if you think the Pingee will read only the SMS message, it will have just the first 50 chars of your message!';

export const PING_USER_HELP_TEXT = 'Ping a practice admin at this practice, or any HQ admin (HR or regional manager) to let them know they should come and participate in this conversation.';

export const BASE_URLS = {
  getPractices: 'practices/admin',
};

export const BADGE_INFO_TEXT = "Badges can be use to organize non-staff workers into groups so that you can address them as a group with a Post badge.  Example - 'Gold' badge relief workers who are known and have worked at the practice before, or 'Silver' for 2nd tier relief.  Another example - 'Agency' badge relief agencies so that they can be reached separately from the public.";

export const JOB_TYPE_SUB_CATEGORY_OBJ = {
  'telemedicine-vet': 'Telemedicine',
  'in-person': 'In-Person',
  'telemedicine-in-person': 'In-Person and Telemed.',
};

export const TIMESTAMPS = Object.freeze({
  twentySixHoursTS: 60 * 60 * 26 * 1000,
  eightHoursTS: 60 * 60 * 8 * 1000,
});

export const LOGIN_ERROR_MESSAGES = {
  INVALID_EMAIL: 'Invalid Email',
  INVALID_PASSWORD: 'Invalid Password',
  EMAIL_REQUIRED: 'Please enter an email to login',
  ACCESS_DENIED: 'Access Denied',
  PASSWORD_REQUIRED: 'Please enter a password to login',
  EMPLOYERS_ACCESS_DENIED: 'Wrong user',
};
export const IMG_STORAGE_LINK = 'https://storage.googleapis.com/jobs-unleashed-common/employers/img/';
export const IMG_STORAGE_TALENT_LINK = 'https://storage.googleapis.com/jobs-unleashed-common/talents/images/';
export const SCHOOL_LOGOS_STORAGE_LINK = 'https://storage.googleapis.com/jobs-unleashed-common/school-logos/';

export const AGENCY_NAMES = [
  { code: 'ROO', name: 'Roo' },
  { code: 'INDEVET', name: 'Indevet' },
  { code: 'VETIQ', name: 'VetIQ' },
  { code: 'RSVP', name: 'RSVP' },
  { code: 'HOLIDAY_VET', name: 'Holiday Vet' },
  { code: 'RELIEF_ROVER', name: 'Relief Rover' },
  { code: 'ALL_FOUR_PAWS_VETERINARY_RELIEF', name: 'All Four Paws Veterinary Relief' },
  { code: 'LIGHTHOUSE_VETERINARY_PERSONNEL_SERVICES', name: 'Lighthouse Veterinary Personnel Services' },
  { code: 'OTHER', name: 'Other Agency', option: '' },
];

export const ROTATION_TYPES = [
  'Acupuncture',
  'Anesthesiology',
  'Avian/Exotics',
  'Bovine',
  'Cardiology',
  'Critical Care',
  'Dermatology',
  'Equine',
  'Emergency',
  'General Practice',
  'Internal Medicine',
  'Mixed Practice',
  'Neurology',
  'Nutrition',
  'Oncology',
  'Ophthalmology',
  'Radiology',
  'Radiation Oncology',
  'Rehabilitation',
  'Surgery',
  'Urgent Care',
];
export const ROTATION_TYPES_OBJ = ROTATION_TYPES.map(item => ({
  label: item,
  title: item,
}));
